import { SELECT_ENGLISH, SELECT_CHINESE } from "../actions/types";

export const initialState = false;

export const SelectLanguageReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case SELECT_ENGLISH:
      return true;
    case SELECT_CHINESE:
      return false;
    default:
      return state;
  }
};
