import React, { Component } from "react";
import { Link } from "react-router-dom";

const collections = {
  chinese: {
    home: "主页",
    business: "经营范围",
    partner: "合作伙伴",
    join: "加入我们",
    contact: "联系我们",
    about3:
      "润达是加拿大的一家金融服务公司。我们的目标是满足每一个家庭和企业的金融财务需求，为每一位客户提供我们卓越的产品和服务。",
    title: "润达金融",
  },
  english: {
    home: "Home",
    business: "Business",
    partner: "Partners",
    join: "Join Us",
    contact: "Contact Us",
    about3:
      "Runda Financial is a Canadian financial services company. Our goal is to meet the financial needs of every family and business, and to provide every customer with our superior products and services.",
    title: "Runda Financial",
  },
};
class Footer extends Component {
  render() {
    const data = this.props.isEnglish
      ? collections.english
      : collections.chinese;
    return (
      <>
        <footer class="main-footer">
          <div class="auto-container">
            <div class="widgets-section">
              <div class="row clearfix">
                <div class="column col-lg-8">
                  <div class="footer-widget logo-widget">
                    <div class="widget-content">
                      <div class="footer-logo">{data.title}</div>
                      <div class="text">{data.about3}</div>
                      {/* <ul class="social-links clearfix">
                                                <li><Link to={'/#'}><span class="fab fa-facebook-f"></span></Link></li>
                                                <li><Link to={'/#'}><span class="fab fa-twitter"></span></Link></li>
                                                <li><Link to={'/#'}><span class="fab fa-vimeo-v"></span></Link></li>
                                                <li><Link to={'/#'}><span class="fab fa-instagram"></span></Link></li>
                                            </ul> */}
                    </div>
                  </div>
                </div>

                <div class="column col-lg-4">
                  <div class="footer-widget links-widget">
                    <div class="widget-content">
                      <div class="row">
                        <div class="col-md-6">
                          <ul>
                            <li>
                              <Link to={"/"}>{data.home}</Link>{" "}
                            </li>
                            <li>
                              <Link to={"/business"}>{data.business}</Link>
                            </li>
                            <li>
                              <Link to={"/partners"}>{data.partner}</Link>
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-6">
                          <ul>
                            <li>
                              <Link to={"/careers"}>{data.join}</Link>
                            </li>
                            <li>
                              <Link to={"/contact"}>{data.contact}</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="auto-container">
            <div class="footer-bottom">
              <div class="copyright">
                Copyright By <Link to={"/#"}>Runda Financial</Link> -{" "}
                {new Date().getFullYear()}
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
