import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
// import ServiceFilter1 from '../element/service-filter1'
import { connect } from "react-redux";

const aboutbg = require("./../../assets/images/background/image-11.jpg");
// const casebg = require('./../../assets/images/background/image-14.jpg');

const collections = {
  chinese: {
    serviceTitle: "我们提供",
    home: "主页",
    business: "经营范围",
    businessCate: [
      "分红人寿保险",
      "定期意外险",
      "重病保险",
      "长期护理保险",
      "残障险",
      "骨折险",
      "年金",
      "医疗和牙科保险",
      "注册教育储蓄计划",
      "注册养老储蓄计划",
      "免税储蓄账户",
      "借款投资计划",
      "旅游保险",
      "即时融资安排",
    ],
    product: "产品",
  },
  english: {
    serviceTitle: "What We Do",
    home: "Home",
    business: "business",
    businessCate: [
      "Whole Life",
      "Term insurance",
      "Critical illness",
      "Long term care",
      "Disability",
      "ACCI-7",
      "Annuity",
      "Dental",
      "RESP",
      "RRSP",
      "TFSA",
      "Loan Investment",
      "Travel",
      "IFA",
    ],
    product: "Product",
  },
};

const icons = [
  "flaticon-insurance",
  "flaticon-money",
  "flaticon-life-insurance",
  "flaticon-health-insurance-2",
  "flaticon-assets",
  "flaticon-home-insurance",
  "flaticon-money-1",
  "flaticon-user",
  "flaticon-save-money",
  "flaticon-innovation",
  "flaticon-money",
  "flaticon-health-insurance",
  "flaticon-world",
  "flaticon-contract",
];
class Services1 extends Component {
  loadCircle = () => {
    function calcCircle(a) {
      for (var i = 0; i < a.length; i++) {
        var container = a[i].parentElement,
          width = container.offsetWidth,
          height = container.offsetHeight,
          radius = width / 2,
          step = (2 * Math.PI) / a.length;
        var x = width / 2 + radius * Math.cos(step * i) - a[i].offsetWidth / 2;
        var y =
          height / 2 + radius * Math.sin(step * i) - a[i].offsetHeight / 2;

        a[i].style.left = x + "px";
        a[i].style.top = y + "px";
      }
    }
    calcCircle(document.querySelectorAll("#compass > .point"));
  };

  componentDidMount() {
    this.loadCircle();
  }
  componentWillUnmount() {
    this.loadCircle();
  }

  render() {
    const data = this.props.isEnglish
      ? collections.english
      : collections.chinese;

    return (
      <>
        <Header />

        {/* <!--Search Popup--> */}
        <div id="search-popup" class="search-popup">
          <div class="close-search theme-btn">
            <span class="flaticon-cancel"></span>
          </div>
          <div class="popup-inner">
            <div class="overlay-layer"></div>
            <div class="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html"
              >
                <div class="form-group">
                  <fieldset>
                    <input
                      type="search"
                      class="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      class="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul class="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <!-- Page Banner Section --> */}
        <section class="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div class="bottom-rotten-curve alternate"></div>

          <div class="auto-container">
            <h1>{data.serviceTitle}</h1>
            <ul class="bread-crumb clearfix">
              <li>
                <Link to={"/#"}>{data.home}</Link>
              </li>
              <li class="active">{data.business}</li>
            </ul>
          </div>
        </section>
        {/* <!--End Banner Section --> */}
        <section class="custom-service-div services-section-three style-two">
          <div class="auto-container">
            <div class="row">
              {/* <!-- Services Block One --> */}
              {data.businessCate.map((v, i) => (
                <div class="service-block-one col-lg-4">
                  <div class="inner-box">
                    <Link to={`/product/${i}`}>
                      <div class="icon">
                        <span class={icons[i]}></span>
                        <span class="round-shape"></span>
                      </div>
                      <h3>{v}</h3>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <div id="compass">
          {data.businessCate.map((v, i) => {
            return (
              <div class="point service-block-one">
                <div class="inner-box circle-inner-box">
                  <Link to={`/product/${i}`}>
                    <div class="icon">
                      <span class={icons[i]}></span>
                      <span class="round-shape"></span>
                    </div>
                    <h3>{v}</h3>
                  </Link>
                </div>
              </div>
            );
          })}
          <div class="center-point">{data.product}</div>
        </div>

        {/* <!-- Case Studies --> */}

        <Footer isEnglish={this.props.isEnglish} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isEnglish: state.language,
});
export default connect(mapStateToProps)(Services1);
