import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const collections = {
  chinese: {
    home: "主页",
    business: "经营范围",
    partner: "合作伙伴",
    join: "加入我们",
    contact: "联系我们",
  },
  english: {
    home: "Home",
    business: "Business",
    partner: "Partners",
    join: "Join Us",
    contact: "Contact Us",
  },
};
class Menu extends Component {
  render() {
    const data = this.props.isEnglish
      ? collections.english
      : collections.chinese;
    return (
      <>
        <div
          class="collapse navbar-collapse show clearfix"
          id="navbarSupportedContent"
        >
          <ul class="navigation clearfix">
            <li>
              <Link class="nav-url-click" to={"/"}>
                {data.home}
              </Link>{" "}
            </li>
            <li>
              <Link class="nav-url-click" to={"/business"}>
                {data.business}
              </Link>
            </li>
            <li>
              <Link class="nav-url-click" to={"/partners"}>
                {data.partner}
              </Link>
            </li>
            <li>
              <Link class="nav-url-click" to={"/careers"}>
                {data.join}
              </Link>
            </li>
            <li>
              <Link class="nav-url-click" to={"/contact"}>
                {data.contact}
              </Link>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isEnglish: state.language,
});

export default connect(mapStateToProps)(Menu);
