import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import { createBrowserHistory } from "history";
import { rootReducer } from "./reducers";

export const history = createBrowserHistory();

export const store = createStore(
  rootReducer(history),
  composeWithDevTools(applyMiddleware())
);
