import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Menu from "./menu";
import {
  selectEnglish,
  selectChinese,
} from "../../store/actions/SelectLanguageActions";
import { connect } from "react-redux";

const options = [
  { value: "english", label: "English" },
  { value: "chinese", label: "中文" },
];

const collections = {
  chinese: {
    title: "润达金融",
    language: "语言",
    languageOption: options[1],
    phone: "电话",
    email: "邮箱地址",
  },
  english: {
    title: "Runda Financial",
    language: "Language",
    languageOption: options[0],
    phone: "Phone Number",
    email: "Email Address",
  },
};

class Header extends Component {
  state = {
    scrolled: false,
  };

  componentDidMount() {
    var btn = document.querySelector(".mobile-nav-toggler");
    var closeBtn = document.querySelector(".close-btn");
    var body = document.getElementsByTagName("body")[0];

    function addFunc() {
      return body.classList.add("mobile-menu-visible");
    }
    function closeFunc() {
      return body.classList.remove("mobile-menu-visible");
    }

    btn.addEventListener("click", addFunc);
    closeBtn.addEventListener("click", closeFunc);

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    if (window.scrollY > 100) {
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  };

  render() {
    const { scrolled } = this.state;
    const data = this.props.isEnglish
      ? collections.english
      : collections.chinese;

    const customStyles = {
      option: (provided) => ({
        ...provided,
        color: "black",
      }),
      control: (provided) => ({
        ...provided,
        color: "black",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: "black",
      }),
    };

    return (
      <>
        <header class={scrolled ? "main-header fixed-header" : "main-header"}>
          <div class="header-top">
            <div class="auto-container">
              <div class="inner">
                <div class="top-left">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div class="icon">
                      <img
                        src={require("../../assets/images/logo-3.png")}
                        alt=""
                        style={{ width: 150, marginRight: 20 }}
                      />
                    </div>
                    {/* <h4>{data.title}</h4> */}
                  </div>
                </div>

                <div class="top-middile">
                  <div class="language">
                    <span>{data.language}:</span>
                    <form action="#" class="language-switcher">
                      <Select
                        options={options}
                        value={data.languageOption}
                        styles={customStyles}
                        onChange={(e) => {
                          if (e.value === "english") {
                            this.props.selectEnglish();
                          } else {
                            this.props.selectChinese();
                          }
                        }}
                      />
                    </form>
                  </div>
                  <div class="contact-info">
                    <div class="single-info">
                      <div class="icon-box">
                        <i class="flaticon-call-1"></i>
                      </div>
                      <div class="text">{data.phone}</div>

                      <h4>
                        <Link to={'/tel:7782510721"'}>778-251-0721</Link>
                      </h4>
                    </div>
                    <div class="single-info">
                      <div class="icon-box">
                        <i class="flaticon-email-4"></i>
                      </div>
                      <div class="text">{data.email}</div>
                      <h4>
                        <Link to={"mailto:rundafinancial@gmail.com"}>
                          rundafinancial@gmail.com
                        </Link>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="header-upper">
            <div class="auto-container">
              <div class="inner-container">
                <div class="nav-outer clearfix">
                  <div class="mobile-nav-toggler">
                    <span class="icon fal fa-bars"></span>
                  </div>

                  <nav class="main-menu navbar-expand-md navbar-light">
                    <Menu />
                  </nav>

                  {/* 

                                    <ul class="social-links clearfix">
                                        <li><Link to={'/#'}><span class="fab fa-facebook-f"></span></Link></li>
                                        <li><Link to={'/#'}><span class="fab fa-twitter"></span></Link></li>
                                        <li><Link to={'/#'}><span class="fab fa-linkedin-in"></span></Link></li>
                                        <li><Link to={'/#'}><span class="fab fa-pinterest-p"></span></Link></li>
                                    </ul> */}
                </div>
              </div>
            </div>
          </div>

          <div
            class={
              scrolled ? "sticky-header animated slideInDown" : "sticky-header"
            }
          >
            <div class="auto-container clearfix">
              <div class="logo pull-left">
                <h4>{data.title}</h4>
              </div>

              <div class="pull-right">
                <nav class="main-menu clearfix">
                  <Menu />
                </nav>
              </div>
            </div>
          </div>

          <div class="mobile-menu">
            <div class="menu-backdrop"></div>
            <div class="close-btn">
              <span class="icon flaticon-cancel"></span>
            </div>

            <nav class="menu-box">
              <div class="nav-logo">
                <Link to={"/#"}>
                  <img src="assets/images/logo-3.png" alt="" title="" />
                </Link>
              </div>
              <div class="menu-outer">
                <Menu />
              </div>
            </nav>
          </div>
        </header>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isEnglish: state.language,
});

const mapDispatchToProps = {
  selectChinese: selectChinese,
  selectEnglish: selectEnglish,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
