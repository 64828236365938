import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Nav, Tab, } from 'react-bootstrap';
import Header from "../layout/header";
import Footer from "../layout/footer";

import { connect } from "react-redux";

const aboutbg = require("./../../assets/images/background/image-11.jpg");
const touchbg = require("./../../assets/images/background/image-8.jpg");

const collections = {
  chinese: {
    team: "出类拔萃",
    team1: "团队成员",
    serviceTitle: "加入我们",
    home: "主页",
    business: "加入我们",
    touch: "保持联络",
    touch1: "联系我们",
    yourName: "您的姓名",
    yourName1: "在此输入您的姓名......",
    yourEmail: "您的邮箱地址",
    yourEmail1: "在此输入您的邮箱地址......",
    yourMessage: "您的消息",
    yourMessage1: "在此输入您的消息......",
    why: "加入我们吧！",
    why2: "求贤若渴",
    why1: "润达金融诚邀有志之士加入我们， 如果你想找一份时间自由，并能同时兼顾家庭的工作， 那么你需要我们。如果你在原来的工作单位没有上升的空间，那么你需要我们。如果你对目前的工作失去兴趣， 无挑战，那么你也需要我们。我们有最全面的培训经验，有各位前辈的携手共进，有客户的不断支持，以及我们所有理财顾问的专业贡献，造就了今天的润达。加入我们，一起开创美好的明天！",
  },
  english: {
    team: "Team",
    team1: "Our Team",
    serviceTitle: "Careers",
    home: "Home",
    business: "Careers",
    touch: "Contact Us Here",
    touch1: "Get In Touch",
    yourName: "Enter your name",
    yourName1: "Enter name here......",
    yourEmail: "Enter your email",
    yourEmail1: "Enter email here......",
    yourMessage: "Enter your message",
    yourMessage1: "Enter message here......",
    why: "Join Us!",
    why1: "We are looking for ambitious candidates to join us. If you are looking for a job with free time and family, you need us. If you can't move up in your old job, you need us. If you're uninterested and unchallenged in your current job, you need us, too. We have the most comprehensive training experience, the hand in hand of our predecessors, the continuous support of customers, and the professional contribution of all our financial advisers, creating today's Runda. Join us to create a better tomorrow!",
    why2: "Urgent need of talents",
  },
};

class Careers extends Component {
  render() {
    const data = this.props.isEnglish
      ? collections.english
      : collections.chinese;

    return (
      <>
        <Header />

        {/* <!--Search Popup--> */}
        <div id="search-popup" class="search-popup">
          <div class="close-search theme-btn">
            <span class="flaticon-cancel"></span>
          </div>
          <div class="popup-inner">
            <div class="overlay-layer"></div>
            <div class="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html"
              >
                <div class="form-group">
                  <fieldset>
                    <input
                      type="search"
                      class="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      class="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul class="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <!-- Page Banner Section --> */}
        <section class="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div class="bottom-rotten-curve alternate"></div>

          <div class="auto-container">
            <h1>{data.serviceTitle}</h1>
            <ul class="bread-crumb clearfix">
              <li>
                <Link to={"/#"}>{data.home}</Link>
              </li>
              <li class="active">{data.business}</li>
            </ul>
          </div>
        </section>
        {/* <!--End Banner Section --> */}

        <section class="contact-section-two">
          <div class="auto-container">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div class="contact-info-area">
              <div class="contact-info">
                <div class="sec-title text-center">
                  <div class="sub-title">{data.team}</div>
                  <h2>{data.team1}</h2>
                </div>
                <div class="row">
                  <div class="info-column col-lg-3">
                    <div class="advisor-box icon-box">
                      <div class="advisor-image icon">
                        <img
                          src={require("../../assets/images/xu.png")}
                          alt=""
                          style={{ width: 200, marginRight: 20 }}
                        />
                      </div>
                      <h3>Shirlene Xu</h3>
                      <p>Advisor</p>
                    </div>
                  </div>
                  <div class="info-column col-lg-3">
                    <div class="advisor-box icon-box">
                      <div class="advisor-image icon">
                        <img
                          src={require("../../assets/images/huang.png")}
                          alt=""
                          style={{ width: 200, marginRight: 20 }}
                        />
                      </div>
                      <h3>Abby Huang </h3>
                      <p>Advisor</p>
                    </div>
                  </div>
                  <div class="info-column col-lg-3">
                    <div class="advisor-box icon-box">
                      <div class="advisor-image icon">
                        <img
                          src={require("../../assets/images/zeng.png")}
                          alt=""
                          style={{ width: 200, marginRight: 20 }}
                        />
                      </div>
                      <h3>Selena Zeng</h3>
                      <p>Consultant</p>
                    </div>
                  </div>
                  <div class="info-column col-lg-3">
                    <div class="advisor-box icon-box">
                      <div class="advisor-image icon">
                        <img
                          src={require("../../assets/images/cheung.png")}
                          alt=""
                          style={{ width: 200, marginRight: 20 }}
                        />
                      </div>
                      <h3>Sam Cheung </h3>
                      <p>Security Consultant</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Services Section Two --> */}
        <section class="services-section-two services-section-careers">
          <div class="auto-container">
            <div class="wrapper-box">
              <div
                class="left-column"
                style={{ backgroundImage: "url(" + touchbg + ")" }}
              >
                <div class="sec-title light">
                  <div class="sub-title">{data.touch}</div>
                  <h2>{data.touch1}</h2>
                </div>
                {/* <!-- Contact Form--> */}
                <div class="contact-form">
                  <form
                    method="post"
                    action="http://azim.commonsupport.com/Finandox/sendemail.php"
                    id="contact-form"
                  >
                    <div class="row clearfix">
                      <div class="col-md-12 form-group">
                        <input
                          type="text"
                          name="username"
                          placeholder={data.yourName1}
                          required=""
                        />
                        <i class="fas fa-user"></i>
                      </div>

                      <div class="col-md-12 form-group">
                        <input
                          type="email"
                          name="email"
                          placeholder={data.yourEmail1}
                          required=""
                        />
                        <i class="fas fa-envelope"></i>
                      </div>

                      <div class="col-md-12 form-group">
                        <textarea
                          name="message"
                          placeholder={data.yourMessage1}
                        ></textarea>
                        <i class="fas fa-edit"></i>
                      </div>

                      <div class="col-md-12 form-group">
                        <button
                          class="theme-btn btn-style-one"
                          type="submit"
                          name="submit-form"
                        >
                          <span class="btn-title">{data.touch1}</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="right-column">
                <div class="services-content">
                  <div class="sec-title">
                    <div class="sub-title">{data.why2}</div>
                    <h2>{data.why}</h2>
                  </div>
                  <div class="text">{data.why1}</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer isEnglish={this.props.isEnglish} />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  isEnglish: state.language,
});
export default connect(mapStateToProps)(Careers);
