import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import GoogleMaps from "simple-react-google-maps";
import { connect } from "react-redux";
import emailjs from "@emailjs/browser";

const aboutbg = require("./../../assets/images/background/image-11.jpg");

const collections = {
  chinese: {
    serviceTitle: "联系我们",
    home: "主页",
    business: "联系",
    touch: "保持联络",
    touch1: "联系我们",
    yourName: "您的姓名",
    yourName1: "在此输入您的姓名......",
    yourEmail: "您的邮箱地址",
    yourEmail1: "在此输入您的邮箱地址......",
    yourMessage: "您的消息",
    yourMessage1: "在此输入您的消息......",
    why: "加入我们吧！",
    why2: "求贤若渴",
    why1: "润达金融诚邀有志之士加入我们， 如果你想找一份时间自由，并能同时兼顾家庭的工作， 那么你需要我们， 如果你在原来的工作单位没有上升的空间，那么你需要我们， 如果你对目前的工作失去兴趣， 无挑战，那么你也需要我们。，我们有最全面的培训经验，有各位前辈的携手共进，有客户的不断支持，以及我们所有理财顾问的专业贡献，造就了今天的润达。加入我们，一起开创美好的明天！",
    office: "办公地址",
    phone: "电话",
    email: "邮箱地址",
    team: "出类拔萃",
    team1: "团队成员",
  },
  english: {
    serviceTitle: "Contact Us",
    home: "Home",
    business: "Contact",
    touch: "Contact Us Here",
    touch1: "Get In Touch",
    yourName: "Enter your name",
    yourName1: "Enter name here......",
    yourEmail: "Enter your email",
    yourEmail1: "Enter email here......",
    yourMessage: "Enter your message",
    yourMessage1: "Enter message here......",
    why: "Join Us!",
    why1: "......",
    why2: "...",
    phone: "Phone Number",
    email: "Email Address",
    office: "Office Address",
    team: "Team",
    team1: "Our Team",
  },
};

const SERVICE_ID = "service_j73qw3i";
const TEMPLATE_ID = "template_3g90ych";
const USER_ID = "2r-2_igUUVsglJPeW";
class Contact extends Component {
  render() {
    const data = this.props.isEnglish
      ? collections.english
      : collections.chinese;

    const handleOnSubmit = (e) => {
      e.preventDefault();
      emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
      e.target.reset();
    };

    return (
      <>
        <Header />

        {/* <!--Search Popup--> */}
        <div id="search-popup" class="search-popup">
          <div class="close-search theme-btn">
            <span class="flaticon-cancel"></span>
          </div>
          <div class="popup-inner">
            <div class="overlay-layer"></div>
            <div class="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html"
              >
                <div class="form-group">
                  <fieldset>
                    <input
                      type="search"
                      class="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      class="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul class="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <!-- Page Banner Section --> */}
        <section class="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div class="bottom-rotten-curve alternate"></div>

          <div class="auto-container">
            <h1>{data.serviceTitle}</h1>
            <ul class="bread-crumb clearfix">
              <li>
                <Link to={"/#"}>{data.home}</Link>
              </li>
              <li class="active">{data.business}</li>
            </ul>
          </div>
        </section>

        {/* <!-- Map Section --> */}
        {/* <!-- Map Section --> */}
        {/* <section class="map-section">
          <div class="map-column">
            <GoogleMaps
              apiKey={"AIzaSyDf32WklxDyHV1vOJaIjfzIQ7n4Khmk6Wo"}
              style={{ height: "800px", width: "100%" }}
              zoom={12}
              center={{ lat: 49.1817738, lng: -123.1371518 }}
              markers={{ lat: 49.1817738, lng: -123.1371518 }} //optional
            />
          </div>
        </section> */}

        {/* <!-- Contact Section Two --> */}
        <section class="contact-section-two">
          <div class="auto-container">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <br />
            <br />
            <br />

            <div class="contact-info-area">
              <div class="contact-info">
                <div class="row">
                  <div class="info-column col-lg-4">
                    <div class="icon-box">
                      <div class="icon">
                        <span class="flaticon-email-6"></span>
                      </div>
                      <h3>{data.email}</h3>
                      <ul>
                        <li>
                          <Link to={"/mailto:rundafinancial@gmail.com"}>
                            rundafinancial@gmail.com
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="info-column col-lg-4">
                    <div class="icon-box">
                      <div class="icon">
                        <span class="flaticon-call-1"></span>
                      </div>
                      <h3>{data.phone}</h3>
                      <ul>
                        <li>
                          <Link to={"/tel:778-251-0721"}>778-251-0721</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="info-column col-lg-4">
                    <div class="icon-box">
                      <div class="icon">
                        <span class="flaticon-location"></span>
                      </div>
                      <h3>{data.office}</h3>
                      <ul>
                        <li>
                          1500-6068 No. 3 Road <br />
                          Richmond BC V6Y4M7
                        </li>
                        <br />
                        <li>
                          2400-4720 Kingsway <br /> Burnaby BC V5H4N2
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="contact-form-area">
              <div class="sec-title text-center">
                <div class="sub-title">{data.touch}</div>
                <h2>{data.touch1}</h2>
              </div>
              {/* <!-- Contact Form--> */}
              <div class="contact-form">
                <form onSubmit={handleOnSubmit} id="contact-form">
                  <div class="row clearfix">
                    <div class="col-md-6 form-group">
                      <label for="name">{data.yourName}</label>
                      <input
                        type="text"
                        name="user_name"
                        id="name"
                        placeholder={data.yourName1}
                        required=""
                      />
                      <i class="fas fa-user"></i>
                    </div>

                    <div class="col-md-6 form-group">
                      <label for="email">{data.yourEmail}</label>
                      <input
                        type="email"
                        name="user_email"
                        id="email"
                        placeholder={data.yourEmail1}
                        required=""
                      />
                      <i class="fas fa-envelope"></i>
                    </div>

                    <div class="col-md-12 form-group">
                      <label for="message">{data.yourMessage}</label>
                      <textarea
                        name="user_message"
                        id="message"
                        placeholder={data.yourMessage1}
                      ></textarea>
                      <i class="fas fa-edit"></i>
                    </div>

                    <div class="col-md-12 form-group">
                      <button class="theme-btn btn-style-one" type="submit">
                        <span class="btn-title">{data.touch1}</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <Footer isEnglish={this.props.isEnglish} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isEnglish: state.language,
});
export default connect(mapStateToProps)(Contact);
