// import React from 'react';
import React from "react";
import Router from "./markup/router";
import "./assets/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/color.css";
import { Provider } from "react-redux";
import { store } from "./store";

export default function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Router />
      </Provider>
    </div>
  );
}
