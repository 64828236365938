import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import Header from "../layout/header";
import Footer from "../layout/footer";
import HomeSlider1 from "./../element/home-slider1";

import { connect } from "react-redux";
import emailjs from "@emailjs/browser";

// import '../../assets/css/color.css';

// import VideoPopup1 from './../element/video-popup1';
// import PortfolioFilter1 from './../element/portfolio-filter1';

// const teambg1 = require('./../../assets/images/background/image-3.jpg');
// const newsbg1 = require('./../../assets/images/background/image-4.jpg');
const wellcomebg1 = require("./../../assets/images/resource/image-1.jpg");

const collections = {
  chinese: {
    banner1: "人身及物产的保障",
    bannerDes1:
      "量身定制个人及家庭专属保障计划，包括重大伤病、老年医疗、穏健退休财务规划等等",
    banner2: "资产的保护及永续传承",
    bannerDes2:
      "追求低税或递延课税，以重新配置资产，完成资产的保全或妥善传承并满足个别目的之赠与安排",
    banner3: "医疗全险需求",
    bannerDes3: "您的医疗保单和身体一样都需要定期检视是否符合高品质医疗的需求",
    key1: "险至",
    key2: "情至",
    key3: "关爱备至",
    about: "关于我们",
    about1: "用心服务",
    about2: "为您守护",
    about3:
      "润达是加拿大的一家金融服务公司。我们的目标是满足每一个家庭和企业的金融财务需求，为每一位客户提供我们卓越的产品和服务。",
    about4:
      "我们主要服务于如何保护家族资产安全， 资产传承策略， 家庭资产配置，企业税务策划， 节税退休计划，保险架构规划， 注册税务优惠账户如： 注册退休储蓄账户， 免税账户， 教育基金账户，以及分隔基金，贷款投资等等",
    phone: "电话",
    email: "邮箱地址",
    serviceTitle: "我们的服务",
    serviceSubTitle1: "倾情提供",
    serviceSubTitle2: "核心保障。",
    service1: "人寿保险",
    service2: "医疗保险",
    service3: "储蓄计划",
    serviceDes1:
      "您的年龄段，健康，性别，和未来计划都会影响保险范围。无论您是年轻的上班族，依靠父母独立生活者、或者是即将退休的商人，我们都能为您提供短期和长期的人寿保险。",
    serviceDes2:
      "随着医疗科技的进步，医疗保险规划也要与时俱进，您的医疗保单和身体一样都需要定期检视是否符合高品质医疗的需求，我们伙伴能够提供您最精准的建议",
    serviceDes3:
      "平均余命的增加代表了退休生活的延长，退休金的准备也是尊严退休生活的唯一保证，我们伙伴能够依照您的风险接受程度设计专属的退休金规划，让退休生活更快乐顺心",
    founder: "创始人",
    founderDes: "全球百万圆桌(MDRT)会员",
    founderDes1:
      "我们相信面对日新月异不足以形容的科技创新、讯息急速膨胀以至泛滥成灾的世界，唯有「险至，情至，关爱备至」是我们永恒不变的价值",
    why: "选择我们",
    why1: "为什么我们能够",
    why2: "为您提供保障？",
    mission: "我们的使命",
    missionDes:
      "为每一个加拿大的家庭实现财富自由和财富传承，为客户的财富保驾护航。",
    vision: "我们的愿景",
    visionDes:
      "满足每一个人理财规划的需求，为每一位客户提供我们卓越的产品和服务。",
    value: "我们的价值",
    valueDes:
      "以客人利益最大化为宗旨，用心守护客人所托与信赖。用最执着的孜孜不倦的精神，为客户设定适合完美的家庭和企业理财规划方案。以坚持不懈、全力以赴、挑战自我、持续成长的精神始终保持谦卑感恩之心，赢得客户信赖，回报社会。",
    touch: "保持联络",
    touch1: "联系我们",
    yourName: "您的姓名",
    yourName1: "在此输入您的姓名......",
    yourEmail: "您的邮箱地址",
    yourEmail1: "在此输入您的邮箱地址......",
    yourMessage: "您的消息",
    yourMessage1: "在此输入您的消息......",
    contactus: "形影不离",
    contactus1: "联系方式",
    office: "办公地址",
    web: "网站",
    title: "润达金融",
  },
  english: {
    banner1: "Protection of persons and property",
    bannerDes1:
      "Tailored personal and family protection plan, including major injury and illness, elderly care, stable retirement financial planning and etc.",
    banner2: "Protection and sustainable inheritance of assets",
    bannerDes2:
      "The pursuit of low or deferred taxation to reallocate assets, complete their preservation or proper inheritance, and satisfy gift arrangements for individual purposes",
    banner3: "Medical coverage needs",
    bannerDes3:
      "Your medical policy, like your health, needs to be reviewed regularly to see if it meets the needs of quality care",
    key1: "Risk",
    key2: "Love",
    key3: "Care",
    about: "About Us",
    about1: "We Work With",
    about2: "You To Address",
    about3:
      "Runda Financial is a Canadian financial services company. Our goal is to meet the financial needs of every family and business, and to provide every customer with our superior products and services.",
    about4:
      "We mainly serve how to protect the security of family assets, asset inheritance strategy, family asset allocation, corporate tax planning, tax-efficient retirement plan, insurance structure planning, registered tax preferential accounts such as: registered retirement savings account, tax-free account, education fund account, segregated fund, loan investment and etc.",
    phone: "Phone Number",
    email: "Email Address",
    serviceTitle: "Our Services",
    serviceSubTitle1: "What Actually We",
    serviceSubTitle2: "Do Here.",
    service1: "Life Insurance",
    service2: "Medical Insurance",
    service3: "Savings Plan",
    serviceDes1:
      "Your age, health, sex, and future plans all affect coverage. Whether you are a young office worker, dependent on your parents, or a businessman about to retire, we can provide you with short and long term life insurance.",
    serviceDes2:
      "With the advancement of medical technology, the medical insurance plan also needs to keep pace with The Times. Your medical insurance policy and your body need to be checked regularly to see if it meets the needs of high quality medical care. Our partners can provide you with the most accurate advice.",
    serviceDes3:
      "The increase in the average remaining life represents the extension of retirement life, and pension preparation is also the only guarantee of a dignified retirement life. Our partners can design exclusive pension planning according to your risk acceptance degree, so that retirement life is more happy and satisfying.",
    founder: "Founder",
    founderDes: "Member of MDRT(Million Dollar Round Table)",
    founderDes1:
      "We believe that in the face of indescribable technological innovation and information expansion and even flooding of the world, only risk, love, care is our eternal value",
    why: "Why Choose Us",
    why1: "why Should You",
    why2: "Choose Us ?",
    mission: "Our Mission",
    missionDes:
      "For every Canadian family to achieve wealth freedom and wealth inheritance, to protect the wealth of customers.",
    vision: "Our Vision",
    visionDes:
      "To meet the needs of every individual financial planning, to provide every customer with our outstanding products and services.",
    value: "Our Value",
    valueDes:
      "To maximize the interests of guests as the purpose, carefully protect the trust and trust of guests. With the most persistent and tireless spirit, for customers to set up the perfect family and business financial planning scheme. With the spirit of perseverance, all-out, self-challenge and continuous growth, we always keep humble and grateful, win the trust of customers and repay the society.",
    touch: "Contact Us Here",
    touch1: "Get In Touch",
    yourName: "Enter your name",
    yourName1: "Enter name here......",
    yourEmail: "Enter your email",
    yourEmail1: "Enter email here......",
    yourMessage: "Enter your message",
    yourMessage1: "Enter message here......",
    contactus: "Get Us Here",
    contactus1: "Contact Us",
    office: "Office Address",
    web: "Web Connect",
    title: "Runda Financial",
  },
};

const SERVICE_ID = "service_j73qw3i";
const TEMPLATE_ID = "template_3g90ych";
const USER_ID = "2r-2_igUUVsglJPeW";
class Index extends Component {
  render() {
    const data = this.props.isEnglish
      ? collections.english
      : collections.chinese;

    const handleOnSubmit = (e) => {
      e.preventDefault();
      emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
      e.target.reset();
    };

    return (
      <>
        <Header />

        {/* <!--Search Popup--> */}
        <div id="search-popup" class="search-popup">
          <div class="close-search theme-btn">
            <span class="flaticon-cancel"></span>
          </div>
          <div class="popup-inner">
            <div class="overlay-layer"></div>
            <div class="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html"
              >
                <div class="form-group">
                  <fieldset>
                    <input
                      type="search"
                      class="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      class="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul class="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <HomeSlider1 data={data} isEnglish={this.props.isEnglish} />

        {/* <!-- Welcome Setion --> */}
        <section class="welcome-section pt-0">
          <div class="auto-container">
            <div class="wrapper-box">
              <div class="row m-0">
                {/* <!-- Welcome Block One --> */}
                <div
                  class="welcome-block-one col-lg-4 wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div class="inner-box">
                    <div class="icon-box">
                      <span class="flaticon-analysis"></span>
                    </div>
                    <h2>{data.key1}</h2>
                  </div>
                </div>
                {/* <!-- Welcome Block One --> */}
                <div
                  class="welcome-block-one col-lg-4 wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div
                    class="inner-box"
                    style={{ backgroundImage: "url(" + wellcomebg1 + ")" }}
                  >
                    <div class="icon-box">
                      <span class="flaticon-tax"></span>
                    </div>
                    <h2>{data.key2}</h2>
                  </div>
                </div>
                {/* <!-- Welcome Block One --> */}
                <div
                  class="welcome-block-one col-lg-4 wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div class="inner-box">
                    <div class="icon-box">
                      <span class="flaticon-work-1"></span>
                    </div>
                    <h2>{data.key3}</h2>
                    {/* <div class="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore. </div> */}
                    {/* <div class="read-more"><Link to={'/#'}>Read More</Link></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- About Section --> */}
        <section class="about-section">
          <div class="auto-container">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="image-wrapper">
                  <div class="image-one">
                    <img
                      src={require("../../assets/images/resource/image-2.jpg")}
                      alt=""
                    />
                  </div>
                  <div
                    class="image-two wow fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1200ms"
                  >
                    <div class="caption">R.</div>
                    <div class="image-outer">
                      <img
                        src={require("../../assets/images/resource/image-3.jpg")}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="content-box">
                  <div class="sec-title">
                    <div class="sub-title">{data.about}</div>
                    <h2>
                      {data.about1} <br />
                      {data.about2}
                    </h2>
                    <div class="text">{data.about3}</div>
                    <div class="text">{data.about4}</div>
                  </div>
                  <div class="row">
                    <div class="info-column col-md-6">
                      <div class="icon-box">
                        <div class="icon">
                          <img
                            src={require("../../assets/images/icons/icon-1.png")}
                            alt=""
                          />
                        </div>
                        <h5>{data.phone}</h5>
                        <h2>778-251-0721</h2>
                      </div>
                    </div>
                    <div class="info-column col-md-6">
                      <div class="icon-box">
                        <div class="icon">
                          <img
                            src={require("../../assets/images/icons/icon-2.png")}
                            alt=""
                          />
                        </div>
                        <h5>{data.email}</h5>
                        <h2>rundafinancial@gmail.com</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Services Section --> */}
        <section class="services-section">
          <div class="auto-container">
            <div class="wrapper-box">
              <div class="left-column">
                <div class="services-content">
                  <div class="sec-title light">
                    <div class="sub-title">{data.serviceTitle}</div>
                    <h2>
                      {data.serviceSubTitle1} <br />
                      {data.serviceSubTitle2}
                    </h2>
                  </div>
                </div>
              </div>
              <div class="right-column" style={{ padding: 50 }}>
                <div
                  class="icon-box wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div class="icon">
                    <img
                      src={require("../../assets/images/icons/icon-3.png")}
                      alt=""
                    />
                  </div>
                  <h2>{data.service1}</h2>
                  <div class="text">{data.serviceDes1} </div>
                </div>
                <div
                  class="icon-box wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div class="icon">
                    <img
                      src={require("../../assets/images/icons/icon-4.png")}
                      alt=""
                    />
                  </div>
                  <h2>{data.service2}</h2>
                  <div class="text">{data.serviceDes2} </div>
                </div>
                <div
                  class="icon-box wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div class="icon">
                    <img
                      src={require("../../assets/images/icons/icon-5.png")}
                      alt=""
                    />
                  </div>
                  <h2>{data.service3}</h2>
                  <div class="text">{data.serviceDes3} </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="services-section">
          <div class="auto-container">
            <div class="wrapper-box">
              <div class="left-column">
                <div class="services-content">
                  <div class="sec-title light">
                    <div class="sub-title">{data.key3}</div>
                    <div class="text" style={{ color: "white" }}>
                      {data.founderDes1}
                    </div>
                  </div>
                </div>
              </div>
              <div class="right-column" style={{ padding: 50 }}>
                <div
                  class="author-box"
                  style={{
                    backgroundImage:
                      "url(assets/images/background/image-1.jpg)",
                  }}
                >
                  <div class="author-info">
                    <div class="author-thumb">
                      <img
                        src={require("../../assets/images/resource/founder.png")}
                        alt=""
                      />
                    </div>
                    <h2>Jenny Zhang 张珈琳</h2>
                    <div class="designation">{data.founder}</div>
                    <div class="text">{data.founderDes}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Gallery Section --> */}
        {/* <PortfolioFilter1 /> */}

        {/* <!-- Video Section --> */}
        {/* <VideoPopup1 /> */}

        {/* <!-- Why Choose Us Section --> */}
        <section class="why-chooseus-section">
          <div class="auto-container">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="content-box">
                  <div class="sec-title">
                    <div class="sub-title">{data.why}</div>
                    <h2>
                      {data.why1}
                      <br />
                      {data.why2}
                    </h2>
                  </div>
                  <Tab.Container defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav nav-tabs tab-btn-style-one"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">
                          <span>{data.mission}</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          <span>{data.vision}</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">
                          <span>{data.value}</span>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content className="tabs-content">
                      <Tab.Pane eventKey="first" className="fadeInUp animated">
                        <div class="clearfix">
                          <div class="image">
                            <img
                              src={require("../../assets/images/resource/image-4.jpg")}
                              alt=""
                            />
                          </div>
                          <div class="text">
                            <p>{data.missionDes}</p>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second" className="fadeInUp animated">
                        <div class="clearfix">
                          <div class="image">
                            <img
                              src={require("../../assets/images/resource/image-4.jpg")}
                              alt=""
                            />
                          </div>
                          <div class="text">
                            <p>{data.visionDes}</p>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third" className="fadeInUp animated">
                        <div class="clearfix">
                          <div class="image">
                            <img
                              src={require("../../assets/images/resource/image-4.jpg")}
                              alt=""
                            />
                          </div>
                          <div class="text">
                            <p>{data.valueDes}</p>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="image-wrapper">
                  <div class="image-one">
                    <img
                      src={require("../../assets/images/resource/image-6.jpg")}
                      alt=""
                    />
                  </div>
                  <div class="image-two">
                    <img
                      src={require("../../assets/images/resource/image-5.jpg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Team Section --> */}
        {/* <section class="team-section" style={{ backgroundImage: "url(" + teambg1 + ")" }}>
                    <div class="auto-container">
                        <div class="sec-title text-center">
                            <div class="sub-title">Our Team</div>
                            <h2>Leadership Team</h2>
                        </div>
                        <div class="row"> */}
        {/* <!-- Team Block One --> */}
        {/* <div class="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div class="inner-box">
                                    <div class="image"><img src={require('../../assets/images/resource/team-1.jpg')} alt="" /></div>
                                    <div class="content">
                                        <div class="designation">Founder</div>
                                        <h3>Rosalina D. William</h3>
                                        <div class="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</div>
                                    </div>
                                </div>
                            </div> */}
        {/* <!-- Team Block One --> */}
        {/* <div class="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div class="inner-box">
                                    <div class="image"><img src={require('../../assets/images/resource/team-2.jpg')} alt="" /></div>
                                    <div class="content">
                                        <div class="designation">CEO</div>
                                        <h3>Velavos H. DesuJa</h3>
                                        <div class="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</div>
                                    </div>
                                </div>
                            </div> */}
        {/* <!-- Team Block One --> */}
        {/* <div class="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div class="inner-box">
                                    <div class="image"><img src={require('../../assets/images/resource/team-1.jpg')} alt="" /></div>
                                    <div class="content">
                                        <div class="designation">Developer</div>
                                        <h3>Abdur Rahman J.</h3>
                                        <div class="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

        {/* <!-- Contact Section --> */}
        <section class="contact-section">
          <div class="auto-container">
            <div class="row">
              <div class="col-lg-6">
                <div class="sec-title">
                  <div class="sub-title">{data.touch}</div>
                  <h2>{data.touch1}</h2>
                </div>
                {/* <!-- Contact Form--> */}
                <div class="contact-form">
                  <form onSubmit={handleOnSubmit} id="contact-form">
                    <div class="row clearfix">
                      <div class="col-md-12 form-group">
                        <label for="name">{data.yourName}</label>
                        <input
                          type="text"
                          name="user_name"
                          id="name"
                          placeholder={data.yourName1}
                          required=""
                        />
                        <i class="fas fa-user"></i>
                      </div>

                      <div class="col-md-12 form-group">
                        <label for="email">{data.yourEmail}</label>
                        <input
                          type="email"
                          name="user_email"
                          id="email"
                          placeholder={data.yourEmail1}
                          required=""
                        />
                        <i class="fas fa-envelope"></i>
                      </div>

                      <div class="col-md-12 form-group">
                        <label for="message">{data.yourMessage}</label>
                        <textarea
                          name="user_message"
                          id="message"
                          placeholder={data.yourMessage1}
                        ></textarea>
                        <i class="fas fa-edit"></i>
                      </div>

                      <div class="col-md-12 form-group">
                        <button class="theme-btn btn-style-one" type="submit">
                          <span class="btn-title">{data.touch1}</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="sec-title">
                  <div class="sub-title">{data.contactus}</div>
                  <h2>{data.contactus1}</h2>
                </div>
                <div class="contact-info">
                  <div class="row">
                    <div class="info-column col-md-6">
                      <div class="icon-box">
                        <div class="icon">
                          <span class="flaticon-email-6"></span>
                        </div>
                        <h3>{data.email}</h3>
                        <ul>
                          <li>
                            <Link to={"mailto:rundafinancial@gmail.com"}>
                              rundafinancial@gmail.com
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="info-column col-md-6">
                      <div class="icon-box">
                        <div class="icon">
                          <span class="flaticon-call-1"></span>
                        </div>
                        <h3>{data.phone}</h3>
                        <ul>
                          <li>
                            <Link to={'/tel:7782510721"'}>778-251-0721</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="info-column col-md-6">
                      <div class="icon-box">
                        <div class="icon">
                          <span class="flaticon-location"></span>
                        </div>
                        <h3>{data.office}</h3>
                        <ul>
                          <li>
                            1500-6068 No 3 Road <br />
                            Richmond BC V6Y4M7
                          </li>
                          <br />
                          <li>
                            2400-4720 Kingsway
                            <br /> Burnaby BC V5H4N2
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="info-column col-md-6">
                      <div class="icon-box">
                        <div class="icon">
                          <span class="flaticon-worldwide"></span>
                        </div>
                        <h3>{data.web}</h3>
                        <ul>
                          <li>
                            <Link to={"https://www.rundafinancial.com/"}>
                              https://www.rundafinancial.com/
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer isEnglish={this.props.isEnglish} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isEnglish: state.language,
});

export default connect(mapStateToProps)(Index);
