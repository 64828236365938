import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import Header from "../layout/header";
import Footer from "../layout/footer";
// import ServiceFilter1 from '../element/service-filter1'
import { connect } from "react-redux";

const aboutbg = require("./../../assets/images/background/image-11.jpg");

const collections = {
  chinese: {
    serviceTitle: "合作伙伴",
    home: "主页",
    business: "合作伙伴",
    collaboration: "亲密无间",
    title: "强力的合作伙伴是我们坚固的后盾",
    des: "润达金融与加拿大各大保险公司精诚合作，为您提供最坚实的保障",
  },
  english: {
    serviceTitle: "Our Partners",
    home: "Home",
    business: "Partners",
    collaboration: "Partners",
    title: "Strong partners are our solid backing",
    des: "Runda financial cooperates with major insurance companies in Canada to provide you with the most solid protection",
  },
};

const partners = [
  { name: "Manulife", color: "#32a852" },
  { name: "Canada Life", color: "#d15e49" },
  { name: "Sunlife", color: "#F4C200" },
  { name: "Equitable Life", color: "#0C5798" },
  { name: " Industrial Alliance", color: "#003DA4" },
  { name: "B2B Bank", color: "#FBB913" },
  { name: "Blue cross", color: "#279FDF" },
  { name: "Manulife Bank", color: "#32a852" },
  { name: "Allianz", color: "#003780" },
  { name: "IA Excellence", color: "#003DA4" },
  { name: "BMO", color: "#1675BE" },
  { name: "RBC", color: "#0759B3" },
  { name: "Tugo", color: "#3ECBB7" },
];
class Services2 extends Component {
  render() {
    const data = this.props.isEnglish
      ? collections.english
      : collections.chinese;

    return (
      <>
        <Header />
        {/* <!--Search Popup--> */}
        <div id="search-popup" class="search-popup">
          <div class="close-search theme-btn">
            <span class="flaticon-cancel"></span>
          </div>
          <div class="popup-inner">
            <div class="overlay-layer"></div>
            <div class="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html"
              >
                <div class="form-group">
                  <fieldset>
                    <input
                      type="search"
                      class="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      class="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul class="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <!-- Page Banner Section --> */}
        <section class="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div class="bottom-rotten-curve alternate"></div>

          <div class="auto-container">
            <h1>{data.serviceTitle}</h1>
            <ul class="bread-crumb clearfix">
              <li>
                <Link to={"/#"}>{data.home}</Link>
              </li>
              <li class="active">{data.business}</li>
            </ul>
          </div>
        </section>
        {/* <!--End Banner Section --> */}

        {/* <!-- Services Section Five --> */}
        <section class="services-section-five">
          <div class="auto-container">
            <Tab.Container defaultActiveKey="first">
              <Nav className="row nav-tabs tab-btn-style-one">
                {partners.slice(0, 4).map((v, i) => (
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      <div class="icon-box" style={{ background: v.color }}>
                        <div class="icon">
                          <span class="flaticon-team"></span>
                        </div>
                        <h5>{v.name}</h5>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Nav className="row nav-tabs tab-btn-style-one">
                {partners.slice(4, 8).map((v, i) => (
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      <div class="icon-box" style={{ background: v.color }}>
                        <div class="icon">
                          <span class="flaticon-team"></span>
                        </div>
                        <h5>{v.name}</h5>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Nav className="row nav-tabs tab-btn-style-one">
                {partners.slice(8, 12).map((v, i) => (
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      <div class="icon-box" style={{ background: v.color }}>
                        <div class="icon">
                          <span class="flaticon-team"></span>
                        </div>
                        <h5>{v.name}</h5>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>

              <Nav className="row nav-tabs tab-btn-style-one">
                {partners.slice(12, 16).map((v, i) => (
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      <div class="icon-box" style={{ background: v.color }}>
                        <div class="icon">
                          <span class="flaticon-team"></span>
                        </div>
                        <h5>{v.name}</h5>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="first" className=" fadeInUp animated">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="image">
                        <img
                          src={require("../../assets/images/resource/image-18.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content pl-lg-4">
                        <div class="sec-title">
                          <div class="sub-title">{data.collaboration}</div>
                          <h2>{data.title}</h2>
                        </div>
                        <div class="text">{data.des}</div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second" className=" fadeInUp animated">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="image">
                        <img
                          src={require("../../assets/images/resource/image-18.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content pl-lg-4">
                        <div class="sec-title">
                          <div class="sub-title">Get Rewards</div>
                          <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2>
                        </div>
                        <div class="text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum.
                        </div>
                        <div class="link-btn">
                          <Link to={"/#"} class="theme-btn btn-style-one">
                            <span class="btn-title">Learn More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="third" className=" fadeInUp animated">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="image">
                        <img
                          src={require("../../assets/images/resource/image-18.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content pl-lg-4">
                        <div class="sec-title">
                          <div class="sub-title">Get Rewards</div>
                          <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2>
                        </div>
                        <div class="text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum.
                        </div>
                        <div class="link-btn">
                          <Link to={"/#"} class="theme-btn btn-style-one">
                            <span class="btn-title">Learn More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="fourth" className=" fadeInUp animated">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="image">
                        <img
                          src={require("../../assets/images/resource/image-18.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content pl-lg-4">
                        <div class="sec-title">
                          <div class="sub-title">Get Rewards</div>
                          <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2>
                        </div>
                        <div class="text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum.
                        </div>
                        <div class="link-btn">
                          <Link to={"/#"} class="theme-btn btn-style-one">
                            <span class="btn-title">Learn More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="fifth" className=" fadeInUp animated">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="image">
                        <img
                          src={require("../../assets/images/resource/image-18.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content pl-lg-4">
                        <div class="sec-title">
                          <div class="sub-title">Get Rewards</div>
                          <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2>
                        </div>
                        <div class="text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum.
                        </div>
                        <div class="link-btn">
                          <Link to={"/#"} class="theme-btn btn-style-one">
                            <span class="btn-title">Learn More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="sixth" className=" fadeInUp animated">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="image">
                        <img
                          src={require("../../assets/images/resource/image-18.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content pl-lg-4">
                        <div class="sec-title">
                          <div class="sub-title">Get Rewards</div>
                          <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2>
                        </div>
                        <div class="text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum.
                        </div>
                        <div class="link-btn">
                          <Link to={"/#"} class="theme-btn btn-style-one">
                            <span class="btn-title">Learn More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </section>

        {/* <!-- Sponsors Section --> */}

        <Footer isEnglish={this.props.isEnglish} />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  isEnglish: state.language,
});
export default connect(mapStateToProps)(Services2);
