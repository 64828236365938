import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Data from "./data";
import { connect } from "react-redux";

const aboutbg = require("./../../assets/images/background/image-11.jpg");

class PortfolioDetails extends Component {
  render() {
    window.scrollTo(0, 0);
    const isEnglish = this.props.isEnglish;
    const { id } = this.props.match.params;
    if (!id) {
      this.props.history.push("/business");
    }
    return (
      <>
        <Header />

        {/* <!-- Page Banner Section --> */}
        <section class="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div class="bottom-rotten-curve alternate"></div>

          <div class="auto-container">
            <h1>{isEnglish ? "Produc Detail" : "产品详情"}</h1>
            <ul class="bread-crumb clearfix">
              <li>
                <Link to={"/business"}>
                  {isEnglish ? "Product" : "经营范围"}
                </Link>
              </li>
              <li class="active">{isEnglish ? "Detail" : "详情"}</li>
            </ul>
          </div>
        </section>
        {/* <!--End Banner Section --> */}

        {/* <!-- Portfolio Details --> */}
        <Data id={id} isEnglish={isEnglish} />

        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  isEnglish: state.language,
});
export default connect(mapStateToProps)(PortfolioDetails);
