import React from "react";

const fenhong = (isEnglish) => {
  return (
    <>
      {!isEnglish ? (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>终身分红保险</h2>
            </div>
            <div class="text">
              近年来，越来越多人喜欢购买分红保险，因为它除了保险保障以外，还具备保值增值的功能。与UL保险一样，分红保险也是终身人寿保险的一种，和其他保险产品不同的是，分红保险投保人可以参与分享保险公司的利润分配。{" "}
            </div>
            <h3>供款模式</h3>
            <div class="text">
              所付保费是一笔固定数额，无法分拆，计划书并不说明其中的成本金额，所付保费的一部分为保险成本，多出部分则用于投资。分红保险的投资方向和管理由保险公司统一监管，投保人无须打理便可参与分红，分红账户内的投资可获得免税增长。记住：
              是免税增长， 不会收到任何的税单
            </div>
            <h3>付款期限</h3>
            <div class="text">
              分红保险和UL保险一样，可以选择保费的付款年限，包括终身付款和快速付清，快速付清一般是10年或者20年期甚至可以短到5年。有能力的话，建议投保人最好选择若干年内付清，以免将来分红下降时造成保费支付时间延长的苦恼。
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://www.ratelab.ca/wp-content/uploads/2018/06/Dividents.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <h3>分红选择</h3>
            <div class="text">
              既然投保人可以参与保险公司的分红，那么，这些分红又可以使用在什么地方呢？其实，保险公司会提供多种方式，投保人可以灵活选择运用。一般来说，有以下四种，但润达理财认为，从税务优惠上看，选择增加保额选项可最大限度减少税务负担。
            </div>
            <ul class="product-detail">
              <li class="product-detail">
                提取现金–可以现金方式收取和获得红利；
              </li>
              <li class="product-detail">
                现金累存–可以将红利存放于中，复利增长；
              </li>
              <li class="product-detail">
                减扣保费–可以将红利用于缴付保单的保费；
              </li>
              <li class="product-detail">
                增加保额–也可将收取的红利自动转化为保额，增加财富积累；
              </li>
            </ul>
            <div class="text">
              虽然分红保险的价格较高，但将人寿保险的保障功能、财富转移功能、资产保护功能和投资功能融为一体，在为投保人提供基本保障的同时，也在延税的基础上积累一笔可观的财富，供投保人在人生的各阶段周转和使用。分红保险主要有以下几大理财功能{" "}
            </div>
            <div class="text">
              第一：分红保险是一种保额不断增长的保险。保险的保障功能，通过投保人所拥有的保额来体现。但纯保障保险（如Term保险和只付基本保费的UL保险）的保额是固定不变的，而分红保险的保额却是随着时间的推移不断增长。这是因为分红保险的基本保费中已经含有投资，每年都有红利被分配到保单里，从而使保单的总保额不断增加。{" "}
            </div>
            <div class="text">
              第二：分红保险是一种非常有效的避税理财工具。分红保险里的投资增长不需要在当年交税，意味着每年的增长值作为新增投资在保单里面继续为投保人创造新的财富，从而可享受到复利增长的威力和好处。而一般的投资理财方式每年的投资增长都需要交增值税，从而大大降低了其增值的速度。另外，分红保险里的投资是由经验丰富的保险公司的专家队伍来负责操作，投保人丝毫不需要为投资操心，尽管安心享受比较安全和稳定的回报。{" "}
            </div>
            <div class="text">
              第三：分红保险是一种重要的退休工具。以分红保险中不断增长的现金值和赔偿额为依托，将来采用保单抵押贷款方式用钱补充退休收入，
              不算作当年收入，不需缴纳增值税，而且也不会影响老年福利的享受。投保人本人用不完的部分以赔偿额的方式留给孩子也是完全免税的。而其它投资理财方式（除了自住房和TFSA）最终都逃不过税务的问题。{" "}
            </div>
            <div class="text">
              第四：分红保险更是一种高效的财富传承手段。从财富的免税转移和传承方面来说，分红保险对于长寿的投保人（也是受保人），其放大、转移财富的效率最高。受保人活得越长，赔偿额就增长得越大，其通过分红保险传承给后代的免税财富就越多、转移的效率也就越高。{" "}
            </div>
            <div class="text">
              最后：分红保险还是一种保护资产安全的最有效方式。按照加拿大有关法律的规定，任何保险产品都具有免债权人追索的功能，也就是说，保单里的现金值和赔偿额作为投保人的一种资产是受到加拿大相关法律的严格保护而不可被债权人所追索的，而其它的投资理财产品则一般没有这个功能，这也是保险类产品（包括年金和保本基金等）所具有的独特优势，这也是高资产人士喜欢购买高额保单的重要原因之一。{" "}
            </div>
            <div class="text">
              总的来说，分红保险是保险和投资捆绑在一起的产品。保费虽然相对较高，但可直接参与保险公司分红，又少了自己打理投资的烦恼，适合收入较高或者净资产较高的人士或家庭，同时，也是高资产家庭将财富免税转给下一代的良好工具。{" "}
            </div>
            <div class="text">
              如果您对分红人寿保险有任何疑问，欢迎随时和我们联系，我们很愿意为您服务！{" "}
            </div>
          </div>
        </div>
      ) : (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>Participate Whole Life Insurance </h2>
            </div>
            <h3>What is participating life insurance?</h3>
            <div class="text">
              It’s lifelong coverage that pays whomever you choose a tax-free
              payment when you die. Your policy is guaranteed to grow in cash
              value as long as you pay your premiums. Cash value is the value of
              the insurance policy that you can access as cash. Your insurance
              payout is reduced when you access your cash value.  
            </div>
            <div class="text">
              Your payments are pooled in a separate account called the
              participating account with other policyowners. The funds are
              professionally managed and may provide you with a dividend.{" "}
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://www.ratelab.ca/wp-content/uploads/2018/06/Dividents.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <h3>How does it work?</h3>
            <ul class="product-detail">
              <li class="product-detail">
                Pay your premiums and your policy’s cash value grows tax-free,
                within limits{" "}
              </li>
              <li class="product-detail">
                Your payments go into the participating account that is
                professionally managed {" "}
              </li>
              <li class="product-detail">
                That money is used to pay for expenses, taxes, insurance claims
                and other items 
              </li>
              <li class="product-detail">
                You can take your dividend as cash, buy more insurance or pay
                for your existing coverage  {" "}
              </li>
              <li class="product-detail">
                Whomever you choose receives a tax-free payment when you die {" "}
              </li>
            </ul>
            <p></p>
            <h3>Benefits at a glance</h3>

            <div class="text">
              Participating whole life insurance provides the following
              benefits:
            </div>
            <div class="text">
              Death benefit. The death benefit and any paid-up additions are
              distributed tax free to named beneficiaries, thereby enhancing
              your estate.
            </div>
            <div class="text">
              Annual vesting. When policy dividends are used to purchase
              additional paid-up insurance in your policy, they form a new
              accumulated cash value “floor” that is guaranteed and cannot be
              reduced, unless initiated by you, the policy owner.
            </div>
            <div class="text">
              Account growth. Guaranteed cash values and policy dividends kept
              in your policy are not subject to tax on the growth during your
              lifetime. This will help you meet your long-term financial goals
              and transfer assets efficiently to your beneficiaries.
            </div>
            <div class="text">
              Access to cash. You can access the accumulated cash value of your
              policy at any time. It is accessible through policy loans, through
              policy withdrawals of the cash value or by pledging the
              accumulated cash value as collateral for a tax-free line of credit
              — all of which provide you with added liquidity and flexibility.
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const yiwai = (isEnglish) => {
  return (
    <>
      {!isEnglish ? (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>
                定期意外险： <br />
                定期保障，保费实惠{" "}
              </h2>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://scdoi.files.wordpress.com/2019/08/personal-accident-insurance-standalone-or-add-on-rider-which-is-better1.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="text">
              可以根据客户所选定的年限来投保，在选定的保险年期内，
              保费不变，可代替房贷保险。案列；
            </div>
            <div class="text">
              李先生今年35岁， 是一家餐厅大厨，家里面唯一赚钱者，年收入$60000
              税前， 太太33岁，在家照顾三个孩子的全职太太。{" "}
            </div>
            <div class="text">
              李先生在餐厅有团体保险（可COVER 太太
              和孩子们一部分的牙齿以及医疗，） 工商险（WCB）{" "}
            </div>
            <div class="text">
              李先生有80 万的房贷， 还剩下20 年， 他的担心有以下两点：{" "}
            </div>
            <div class="text">
              第一：万一生病或者残障谁可以照顾一家大小的生活开支？
            </div>
            <div class="text">
              第二：万一自己有什么意外谁代替他照顾年幼的孩子以及没有收入的太太？
            </div>
            <div class="text">
              李先生只有$250/月的预算，如果你跟李先生有类似的情况， 我们有方案：
            </div>
            <ul class="product-detail">
              <li class="product-detail">
                A，$80万的意外保险20年， 每个月保费$58.32,
              </li>
              <li class="product-detail">
                B，$3475/月保到65岁的残障险代替每个月收入，每个月保费$81
              </li>
              <li class="product-detail">
                C，李先生的团体保险里面没有重疾保险，
                所以他需要在孩子为成年需要20年的保障， $20
                万的重疾险每个月保费$84.42
              </li>
            </ul>
            <p></p>
            <div class="text">
              A+B+C=$223.74 /月，在他们可承担的范围之类有个全面的保障，
              这样李先生一家就可以安安心心生活了。{" "}
            </div>
          </div>
        </div>
      ) : (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>Term insurance</h2>
            </div>
            <h3>What Is Term Life Insurance?</h3>
            <div class="text">
              Term life insurance, also known as pure life insurance, is a type
              of life insurance that guarantees payment of a stated death
              benefit if the covered person dies during a specified term. Once
              the term expires, the policyholder can either renew it for another
              term, convert the policy to permanent coverage, or allow the term
              life insurance policy to terminate.
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://scdoi.files.wordpress.com/2019/08/personal-accident-insurance-standalone-or-add-on-rider-which-is-better1.png"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <h3>Key Takeways</h3>
            <ul class="product-detail">
              <li class="product-detail">
                Term life insurance guarantees payment of a stated death benefit
                to the insured's beneficiaries if the insured person dies during
                a specified term.{" "}
              </li>
              <li class="product-detail">
                These policies have no value other than the guaranteed death
                benefit and feature no savings component as found in a whole
                life insurance product.{" "}
              </li>
              <li class="product-detail">
                Term life premiums are based on a person’s age, health, and life
                expectancy.
              </li>
              <li class="product-detail">
                Depending on the insurance company, it may be possible to turn
                term life into whole life insurance.{" "}
              </li>
              <li class="product-detail">
                You can often purchase term life policies that last 10, 15, or
                20 years.{" "}
              </li>
            </ul>
            <p></p>
          </div>
        </div>
      )}
    </>
  );
};

const zhongji = (isEnglish) => {
  return (
    <>
      {!isEnglish ? (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>重疾保险</h2>
            </div>
            <div class="text">
              后疫情时代，加拿大人对于重疾保险的兴趣越来越浓，毕竟我们永远无法预测不可预知的健康问题。你知道吗？非营利性众筹平台GoFundMe的设计初衷是为了满足各种事件的融资需求，如今基本已经成了事故和疾病的救助平台。这从一个侧面反映了加拿大重疾保险的意义和必要性。重疾保险专门针对那些罹患严重和危急疾病的患者
            </div>
            <h3>什么是重疾保险？</h3>
            <div class="text">
              相比人寿保险和残疾保险，重疾保险（CI）是相对更新的保险品种，因此很多加拿大人都不知道它的存在。简单说下重疾险的背景。1983年，南非的Marius
              Barnard博士创立了重疾险。Barnard博士发现，尽管存活率在不断上升，但这也给患者及其家人带来了财务上的困难。患者没有病逝，人寿保险起不到作用，但回归正常的生活却要产生各种成本。重疾险刚推出时仅覆盖4大类疾病：癌症、心脏病、中风以及心脏搭桥手术。此后，该险种的覆盖范围不断扩大，现在多数重疾险覆盖最多25至26种不同的疾病，比如说多发性硬化症、帕金森症或阿尔茨海默病。
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://cdnblog.etmoney.com/wp-content/uploads/2020/07/Critical-Illness-Insurance.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <h3>重疾保险的不同之处</h3>
            <div class="text">
              很多人都不知道人寿、残疾和重疾保险之间的区别是什么。重疾险之所以重要，一个主要的原因是得益于现代医学的发展，越来越多患上严重疾病的人都存活下来了。
            </div>
            <div class="text">
              比如说，癌症是加拿大的第一大致死原因，占比高达30%。按照估算，加拿大2020年新增癌症病例数22.5万，癌症死亡比例32%。平均下来，每天就有617个加拿大人被诊断为癌症。未来的趋势是，2个加拿大人当中就有1人一生当中预计会得癌症。幸运的是，得益于医学的进步，存活率相比15年前已经高出许多。
            </div>
            <div class="text">
              加拿大实行的是公共医疗，医院内部发生的费用都由政府买单。不过，一旦出院或者是院外相关的成本，比如说你的房贷、治疗费以及医院之间的交通费仍然由你自行承担。在康复过程中，这些费用积少成多，最后也会成为巨大的负担。这时候，重疾保险就有了用武之地。该险种提供一次性的赔付且不限用途，对于患者降低康复成本，重新回归日常生活提供巨大的帮助。
            </div>
            <h3>重疾保险的赔付金怎么用？</h3>
            <div class="text">
              重疾保险保障的是被保人在与疾病对抗过程中发生的各种费用，用途不限
            </div>
            <h3>重疾保险的成本是多少？</h3>
            <div class="text">
              保障成本依据年龄、健康状况以及性别等因素而不同。举个例子：对于一名30岁的健康的加拿大非烟民，$10万保障额每月的起始保费为$40-50。重疾险也提供各种灵活选择，你可以选择保障周期间隔，到期时全额退还保费。{" "}
            </div>
            <div class="text">
              所谓到期全额退还保费，指的是如果保障期间没有发生赔付，保险公司就要退还所有的保费。
              加拿大重疾保险的另一个特点是固定保费。不同于汽车保险，一旦签订保险合同，保险公司不得调整保费或对保单条款进行任何的变更。
            </div>
            <h3>不买重疾保险究竟行不行？</h3>
            <div class="text">
              你可能会觉得，自己遵循健康的生活方式，患病概率很低。不幸的是，这只是你的一厢情愿。事实上，健康意识强弱的两组人，未来患病的概率其实是差不多的。但也有一个重大的差别，如果你属于健康组，战胜疾病的几率要高得多，因此对你来说，重疾保险相比人寿保险更加有用。
            </div>
            <div class="text">
              还有人认为，我也可以自己存钱，等到需要治病用钱的时候再把储蓄或RRSP账户中的资金取出了啊。这虽然也是一个可行的选择，但需要注意税务问题。如果你的存款用来投资，你就无法自己把握退出投资的时机，真到急用钱的时候哪怕亏损也只能割肉。相反，重疾保险的赔款是固定免税的。
            </div>
            <div class="text">
              了解了加拿大重疾保险的以上内容，你就可以自己做判断。压力越小，你专注于康复的机会就越高。
            </div>
            <div class="text">
              即使预算有限，买一些小型的重疾保险也能让你的生活变得更加轻松。想象一下，当你在家休养的时候，自己可以获得$1.5万或$3.0万的额外资金。如果你有任何问题或者想要了解加拿大重疾保险的成本，请随时跟我预约并获得一次免费咨询。{" "}
            </div>
          </div>
        </div>
      ) : (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>Critical Insurance</h2>
            </div>
            <h3>What critical illness insurance ?</h3>
            <div class="text">
              Critical illness insurance provides you with a lump sum of
              financial support following a tragic medical diagnosis. Suppose
              you're unable to work due to a sudden heart attack, stroke, or
              cancer diagnosis. In that case, critical illness offers immediate
              financial relief.
            </div>
            <div class="text">
              Critical illness insurance helps you pay for costs beyond and
              outside of your disability, health, or life insurance plans. It
              can pay for expenses such as assertive modifications to your home
              (e.g. ramps, handrails, or grab bars), travel to and from
              treatment, or even home care costs.{" "}
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://cdnblog.etmoney.com/wp-content/uploads/2020/07/Critical-Illness-Insurance.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <h3>How does critical illness insurance work?</h3>
            <div class="text">
              After comparing quotes and speaking with an insurance broker, you
              begin to pay premiums, either monthly or annually, to cover the
              potential risk of contracting a critical illness. Should you be
              diagnosed with an illness outlined in your policy, you can file a
              claim with your insurance provider. After reviewing any medical
              documentation, they pay you a lump sum of cash to help you gain
              financial stability. Whether it's to cover mortgage payments,
              recurring bills, or modifying your bathroom for easier
              accessibility is up to you.{" "}
            </div>
            <div class="text">
              Know that not all critical illness insurance policies are created
              equal. Be sure to check for exclusions such as early-stage cancers
              or neurological conditions such as dementia. Don’t be afraid to
              shop around to get the best possible critical illness insurance
              coverage for your situation and needs.{" "}
            </div>
            <h3>How much does critical illness insurance cost in Canada?</h3>

            <div class="text">
              The annual premium cost depends on several factors, including your
              age and general health, the number of critical illnesses included
              in the policy, and the length of term (i.e. either 10 or 20 years,
              or up to a specified age 75 or 100).{" "}
            </div>
            <div class="text">
              The most common amount of critical illness coverage purchased is
              $100,000. If a 40-year-old non-smoker buys a 10-year policy worth
              $100,000, it will generally cost about $70 per month. Whereas, a
              25-year-old buying a $50,000 policy could cost as little as $6 per
              month.{" "}
            </div>
            <div class="text">
              As with any health, life, or disability insurance products, the
              older you buy it, the more expensive it becomes.{" "}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const huli = (isEnglish) => {
  return (
    <>
      {!isEnglish ? (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>长期护理险</h2>
            </div>
            <div class="text">
              随着医疗水平和生活水平的提高，人们的寿命在不断延长，但也带来不可忽视的问题：子女工作生活忙碌，照顾父母的时间有限，当家里有人需要护理时更是分身乏术；而由于加国人口老龄化日益严重，政府提供的免费护理服务有限，很难满足人们的护理需求。这种情况下，请私人护理或入住老人院就成为不得不考虑的问题。{" "}
            </div>
            <div class="text">
              一般来说，长期护理包括看护、康复、治疗、起居照顾、家务以及监管等服务。以安省为例，若在家护理，专业护理人员的服务收费目前为$30-$70/小时；若住在护理中心，普通的住宿费为 $2,000-$6,000/月。
            </div>
            <div class="text">
              按上述标准，假如护理费用每月$5,000，一年的护理费用将高达$60,000。这还只是以目前的护理费率水平而计算的，加上增长率以及通货膨胀率，二十年以后将会翻一倍，即每年护理费用达$120,000。但是这笔昂贵的费用又该如何解决呢？{" "}
            </div>
            <div class="text">
              其实，长期护理保险（Long-Term Care
              Insurance，简称LTC）主要就是为此类需求而设计的，保险赔付款可以确保护理服务的费用能够得到解决。长期护理保险是当受保人因故发生“生活不能自理”而需要别人提供长期护理服务时，保险公司会每周向受保人支付一笔款项。{" "}
            </div>
            <div class="text">
              “生活不能自理”就是个人的日常生活和活动，必须在他人的帮助下才能完成。这种情况不一定是因为年老才发生，也可能是因为意外伤残或疾病而导致。在日常起居最基本的6项活动中，包括洗澡、更衣、进餐、如厕、移动或控制排泄，如有任何2项不能自理，即符合长期护理保险的理赔条件。{" "}
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://g.foolcdn.com/editorial/images/493261/getty-long-term-care-insurance-health-healthcare-retirement-planning-protect-policy-premium.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <h3>赔偿金额</h3>
            <div class="text">
              长期护理保险与伤残保险相似，同样是收入替补型的保险。在确认受保人满足赔付条件后，保险公司会每周发放一笔赔偿款，此赔偿款无需交税。每周赔偿金额越高，保费越高。{" "}
            </div>
            <h3>赔偿等候</h3>
            <div class="text">
              长期护理保险在确认满足赔付条件后，投保人可以选择90-180天的赔偿等候，以此调整保费的高低。每个保险公司的等候期设置也有所不同，但等候期越长，保费越低。投保人可以根据自身需要和经济能力，选择不同的等候时间。{" "}
            </div>
            <h3>赔付期限</h3>
            <div class="text">
              赔付期限从100周到250周不等，个别保险公司有无限期赔付，一直到受保人离世为止。赔付期限越长，保费越高。{" "}
            </div>
            <h3>付款期限</h3>
            <div class="text">
              付款期限一般有3种：终身付款、付款到65岁或25年内付清。付款期限越短，保费越高，建议在经济能力允许的情况下，一般选择25年内付清，因为保费总额支出较少。{" "}
            </div>
            <h3>通货膨胀系数</h3>
            <div class="text">
              不少人会担心，现在购买的长期护理保险赔偿金额到未来需要索赔时，由于通货膨胀原因，赔偿金额可能不足以支付护理服务的费用。因此，保险公司也提供通货膨胀保护的附加选项，提供多种通货膨胀率选择，以确保将来有足够的资金支付日益上涨的护理费用。{" "}
            </div>
            <h3>保费退还</h3>
            <div class="text">
              个别保险公司的长期护理保险还有保费退还选项，在受保人离世并无任何索赔的情况下，投入的保费可全数退还，损失的只是一点利息，但获得的却是无限期的保障。{" "}
            </div>
            <div class="text">
              特别需要提醒的是，如果有些申请人曾经由于家族病史、以往看病记录、或某些健康指标过高等因素，而未能成功申请重病保险，长期护理保险可以作为一个替代方案。因为长期护理保险的申请条件比重病保险相对宽松，同时长期护理保险的价格也相对低于重病保险的价格。{" "}
            </div>
            <div class="text">
              长期护理保险是退休规划的重要组成部分，当不幸遇到意外事故或退休后发生健康问题时，使大家可以生活得有尊严，既不用麻烦子女，又有专业人士照顾，因此润达理财建议大家在做退休规划时应慎重考虑长期护理的需求。{" "}
            </div>
            <div class="text">
              想要了解更多关于长期护理保险的信息吗？欢迎随时和我们联系，润达理财很愿意为您服务{" "}
            </div>
          </div>
        </div>
      ) : (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>Long term care</h2>
            </div>
            <h3>About Long-Term Care Insurance</h3>
            <div class="text">
              Canadians are living longer lives thanks to healthier lifestyles
              and medical breakthroughs. Yet as we age, we may lose our ability
              to independently care for ourselves. Provincial health plans may
              cover some of the costs for long-term care, but the majority of
              expenses are covered by families, causing financial hardship.{" "}
            </div>
            <h3>Long-Term Care Statistics in Canada</h3>
            <div class="text">
              Long-term care is an essential service that many Canadian families
              benefit from, but it comes at a high cost that may be unaffordable
              for many. For both government-owned and private long-term care and
              retirement residences, the cost of having a family member stay in
              these organizations can be well over a thousand dollars a month.
              Over time, this expense can be unrealistic for many families to
              continue to keep up with, meaning their family members may go
              without the care they need.{" "}
            </div>
            <div class="text">Consider these statistics: </div>
            <ul class="product-detail">
              <li class="product-detail">
                The cost for a long-term stay at a government-subsidized nursing
                home can be over $2,000 a month.{" "}
              </li>
              <li class="product-detail">
                Privately-owned retirement residence stay can cost on average
                over $3000 a month for a semi-private room and over $4000 for a
                private room.{" "}
              </li>
            </ul>
            <p></p>
            <div class="text">
              Your provincial health insurance plan will not cover the full cost
              of care at home or subsidized facilities. Moreover, your
              provincial plan will not cover the costs of living in a
              privately-owned retirement residency. Are you willing to leave
              these costly expenses to your loved ones?
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://g.foolcdn.com/editorial/images/493261/getty-long-term-care-insurance-health-healthcare-retirement-planning-protect-policy-premium.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <h3>How Long-Term Care Insurance Works</h3>
            <div class="text">
              Long Term Care Insurance covers what your provincial health plan
              does not. This coverage ensures that the cost of long-term care
              does not impact your savings and retirement income as well as
              become a financial strain on your loved ones.{" "}
            </div>
            <div class="text">
              Your rates are typically determined by some basic factors,
              including:{" "}
            </div>
            <ul class="product-detail">
              <li class="product-detail">
                Your age and overall health - the older you are and the more
                health issues you have will determine your premium amounts.{" "}
              </li>
              <li class="product-detail">
                Sex at birth - your sex at birth will also help determine your
                rates. Unlike life insurance coverage, women often pay more than
                men when it comes to long-term care insurance because they
                generally live longer and therefore have a greater chance of
                needing long-term care.{" "}
              </li>
              <li class="product-detail">
                The insurer and the amount of coverage - different insurers have
                different prices for their coverage. Also, the more coverage you
                need, the higher your premiums will ultimately be.{" "}
              </li>
            </ul>
            <p></p>
            <h3>What Long-Term Care Insurance Can Help With</h3>
            <div class="text">
              Long-Term Care Insurance can cover expenses such as:
            </div>

            <ul class="product-detail">
              <li class="product-detail">Care by a certified nurse, </li>
              <li class="product-detail">Rehabilitation and therapy, </li>
              <li class="product-detail">
                Personal care & home care services (assistance with daily
                activities such as dressing, cooking, cleaning)
              </li>
              <li class="product-detail">
                Supervision by another individual.
              </li>
            </ul>
            <p></p>

            <div class="text">
              Please complete a short enquiry form to get a free, no obligations
              quote on Long Term Care Insurance.
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const canji = (isEnglish) => {
  return (
    <>
      {!isEnglish ? (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>残障险</h2>
            </div>
            <div class="text">
              残疾保险是一种‘收入替代’保险。它能够让投保人在发生残疾后不能工作时有一定的收入。大多数人会认为伤残保险离他们很远。但是，事实并不是如此。比如统计资料显示房屋贷款停止支付的原因有一半是由于伤残造成的。{" "}
            </div>
            <div class="text">
              伤残保险可以通过私人计划，政府计划或团体计划获得。因车祸导致的伤残由汽车保险负担。这些伤残保险一般支付投保人65-80%的年收入。保险赔偿费根据具体情况决定是否缴纳所得税。值得提醒大家的是，即使您拥有团体伤残保险，也应该适当考虑私人残疾保险。可以通过合理地调整赔款等待期以使得在残疾期间一直有稳定的收入。{" "}
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://assets-us-01.kc-usercontent.com/0542d611-b6d8-4320-a4f4-35ac5cbf43a6/61081776-d8ac-48e0-8b28-96a695e5f3b8/disability-insurance-coverage-types-header.jpg?w=1110&h=400&fit=crop"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="text">
              保险公司在审批保险申请时是根据投保人的健康状况来决定是否批准该保险;根据投保人的收入来决定可申请的赔偿额;根据投保人的职业来决定保费和保险覆盖范围。{" "}
            </div>
            <div class="text">
              如果投保人在保险生效后发生职业的变化，从高风险职业更换到低风险职业，应该通知保险公司，投保人可能会获得降低保费的优惠。
            </div>
            <div class="text">
              如果您是一个自雇人士，那就更应该拥有伤残保险。伤残保险额由您的生活方式，家庭责任，债务，资金量和长期财务目标决定。{" "}
            </div>
          </div>
        </div>
      ) : (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>Disability Insurance</h2>
            </div>
            <h3>What is disability insurance?</h3>
            <div class="text">
              Disability insurance works when you can’t. If an illness or
              accident keeps you from working, disability insurance can give you
              a tax-free monthly benefit to help you pay your expenses by
              replacing some of your earnings.{" "}
            </div>
            <div class="text">
              You can customize disability insurance coverage with additional
              benefits at an extra cost. An example is a
              return-of-premium rider. With this feature, you may get back up to
              50% of yearly premiums you’ve paid. A cost of living benefit helps
              reduce the impact of inflation by providing yearly adjustments to
              the monthly disability benefit during disability. Other additional
              benefits are available -we can help you select what works best for
              you.{" "}
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://assets-us-01.kc-usercontent.com/0542d611-b6d8-4320-a4f4-35ac5cbf43a6/61081776-d8ac-48e0-8b28-96a695e5f3b8/disability-insurance-coverage-types-header.jpg?w=1110&h=400&fit=crop"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <h3>How does disability insurance work?</h3>

            <div class="text">
              If you become disabled, you file a claim. After your claim is
              approved, there is a waiting period. The waiting period is the
              number of days from the date you’re disabled until the benefit
              start date. This waiting period, chosen at the time you apply, can
              be anywhere from 30 days to a year. After this period is
              fulfilled, your monthly benefit would begin.{" "}
            </div>
            <div class="text">
              The monthly benefit replaces a percentage of your paycheque when
              an illness or accident stops you from working. Your payments stop
              when your benefit period ends, or you return to work. Your
              payments depend on how much coverage you choose to purchase and is
              approved by us at the time you apply. You will work closely with
              us to help determine this amount.{" "}
            </div>
            <h3>What types of disability insurance are there?</h3>
            <div class="text">
              There are two types – short-term and long-term disability
              insurance. They work best together because there’s often a waiting
              period before your benefits start being paid. Long-term disability
              policies also have a typical benefit period. Canada Life offers
              24, 60, and 120 months and to age65.for details please contact us
            </div>
            <div class="text">
              Short-term disability insurance is usually accessed through a plan
              provided by your employer. It often provides coverage for up to 6
              months if you have an illness or are injured. The waiting period
              for short term disability is typically shorter than long-term
              disability.{" "}
            </div>

            <div class="text">
              While long-term disability insurance can be offered by an
              employer, the coverage may not be enough. It’s important to review
              that coverage closely with your employer’s HR representative to
              ensure you have enough. Individual disability insurance ensures
              you’re fully covered if you become disabled for a longer period of
              time.{" "}
            </div>
            <h3>Government benefits</h3>
            <div class="text">
              Some individuals think they don’t need individual disability
              insurance coverage because they’re protected by government
              disability programs like Canada/Quebec Pension Plan (CPP/QPP),
              Workers’ Compensation and employment insurance. Coverage provided
              by these programs may not be comprehensive enough for your needs.
              You can find more about these benefits on the Government of
              Canada’s and Ceridan’s websites.{" "}
            </div>
            <div class="text">
              To learn more about which disability insurance option protects you
              best,contact us today{" "}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const guzhe = (isEnglish) => {
  return (
    <>
      {!isEnglish ? (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>骨折险</h2>
            </div>
            <div class="text">
              作为2018全球最宜居城市的温哥华，一到冬天就是各种滑雪爱好者的天堂了，
              喜欢冬季运动的小伙伴们早已蠢蠢欲动，有些更是按捺不住自己内心的躁动，趁大雪天先拿出装备热热身。
              不过冬季也正是骨折的高发期，不但是因为滑雪的人不少躺着的时间比站着的多，更是因为四处道路湿滑，一不小心就会摔跤。{" "}
            </div>
            <div class="text">
              今天就为大家推荐一款超级物美价廉的保险。其本意是为了对付活泼好动的熊孩子，不过却恰好对加拿大的冬天起到奇效。{" "}
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://www.cndoctor.ca/wp-content/uploads/2021/01/GettyImages-1160585908.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="text">
              只要你是加拿大国籍或PR，都可投保这款保险。这款IA旗下的保险叫做Acci
              7，正如其名字所显示的那样。一个月保费只有7块钱。反正我是没见过这么便宜的正经保险了。这七块钱可以保什么呢？{" "}
            </div>
            <ul class="product-detail">
              <li class="product-detail">
                $7/月 保骨折，根据伤势不同，理赔 $700 - $7,000 不等
              </li>
              <li class="product-detail">
                $7/月 保住院，每天能拿到 $70 住院补助。
              </li>
              <li class="product-detail">
                $7/月 保伤残，如果伤残不能上班，每个月可领到$700块保险金。
              </li>
              <li class="product-detail">
                $7/月 保意外，如果意外去世，赔付$70,000给受益人。
              </li>
            </ul>
            <div class="text">
              当然，这四种保障，每种都是7块钱一个月，如果你全想保，那么一个月总共28块大洋。不仅如此，除了大家最关心的保骨折部分，可以允许保14块钱的，也就是两份，其他选项只能保7块的，也就是说最多，你也只能付35块保费，再多不行啦。因为本来就不赚钱。
              按照惯例我们先搞明白什么不保障：{" "}
            </div>
            <ul class="product-detail">
              <li class="product-detail">自杀</li>
              <li class="product-detail">战争，暴乱 </li>
              <li class="product-detail">开飞机 </li>
              <li class="product-detail">现役军人</li>
              <li class="product-detail">醉驾 </li>
              <li class="product-detail">飙车 </li>
              <li class="product-detail">运动比赛 </li>
              <li class="product-detail">整容（嗯，防止削脸） </li>
              <li class="product-detail">
                故意吸入气体，窒息或中毒（国外生活真野） 
              </li>
              <li class="product-detail">离开加拿大183天以上 </li>
              <li class="product-detail">
                不必要情况下进行高危治疗（仅限保意外部分，防治无必要的截肢）
              </li>
            </ul>
            <div class="text">
              至于对于大家关注最多的保骨折，根据受伤部位不同，赔付
              $700-$7,000不等，最差拿$700块也能买个拐，再来点补品养养伤啊。{" "}
            </div>
            <div class="text">
              80岁以下可投保，但是年龄在70岁以上老人，赔付就会减半了。也就是说原本赔付$7,000，现在只有$3,500了。毕竟老年人是骨折高发人群，这里顺便提醒一下大家注意老年人的补钙+VD。{" "}
            </div>
            <div class="text">
              好啦，这款保险大致先介绍到这里，当然选择不同保障里面有更多详细条款，如果有问题请咨询我们的理财顾问啦，不过7块钱的保险，真心买不了吃亏，买不了上当了，要滑雪或者准备冬天浪起来的小伙伴，除了做好防护，遵守规则，也赶紧考虑下吧。{" "}
            </div>
          </div>
        </div>
      ) : (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>ACCI-7 </h2>
            </div>
            <div class="text">
               Accident Insurance for all Ages (15 days old to age 80)
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://www.cndoctor.ca/wp-content/uploads/2021/01/GettyImages-1160585908.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <h3>Available Benefits</h3>
            <ul class="product-detail">
              <li class="product-detail">
                Accidental fracture coverage - $7/month for up to $7,000 benefit
                or $14/Month for up to $14,000 benefit
              </li>
              <li class="product-detail">
                Accidental death and dimenberment - $7/month for $7,000 lump sum
              </li>
              <li class="product-detail">
                Hospital benefit in event of accident - $7/month for $7,000
                daily benefit
              </li>
              <li class="product-detail">
                Disability insurance - $7/month for $700 monthly benefit
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

const nianjin = (isEnglish) => {
  return (
    <>
      {!isEnglish ? (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>
                年金: 在加拿大退休
                <br />
                你一定还不知道的一种宝藏
                <br />
                退休金模式
              </h2>
            </div>
            <div class="text">
              我们能想象退休后，还在过这种生活吗？ 继续为资产打工，各种操劳，
              比如投资的房子房贷到期，要跑银行做加按贷，租客下个月要搬走，要联系清洁公司和打广告找新租客，最近股市大跌，要找理财顾问理论一番，怎么账户里买的基金
              比股市跌得还多，这是我们想要的生活吗？
            </div>
            <div class="text">
              至少对我来说，我渴望退休后，生活是平静和安逸的，
              可以在身体还硬朗的时候，多出去走走，
              看遍大好山河，尝遍人间美食，享受世间的一切美好，那我们把投资房卖了，理财通通变成保本的，放在银行里慢慢取
              总行吧？
              好像也不行，心里还有点没底，如果从此就开始吃老本，总有吃完的一天吧？万一人还活着，钱先花完了，那就太悲哀了。
            </div>
            <div class="text">
              那有什么既可以不用操心管理，还可以保证有永远花不完的钱的办法吗？有的，在中国，它叫年金险，在加拿大，叫年金（Annuity），
              可以理解为一种个人退休金
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://blog.mint.com/wp-content/uploads/2020/09/how-do-annuities-work.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="text">我们来举个例子来说明它到底是什么：</div>
            <div class="text">
              王哥今年60岁，来加拿大20年了，将从一个技术岗位退休。
              他平时喜欢钓鱼，打算退休后夏天在加拿大，冬天回中国，去各
              大水库钓鱼，
              他的子女都已经成年，不用他操心，他就想着有一份稳定的退休收入，不用管理的，保本的，不跌
              的，一直发放到他终老，
              他有一个RRSP账户，价值50万加币，根据他所描述的需求，理
              财顾问建议他使用年金。
              将全部50万交给年金公司，按照王哥的年龄等各项因素计算，核
              心条款是：保证每月固定发放退休金*$1,987加币，终身发放。
            </div>

            <div class="text">重点是保证和终身这两个关键词：</div>
            <div class="text">
              保证，就是不管发生什么情况，例如金融危机，股市崩盘，房地
              产市场崩溃等事件，退休金不受影响，保证足额发放。{" "}
            </div>
            <div class="text">
              终身，顾名思义，即使王哥活到120岁，退休金要领长达60年，
              也会一直发放下去，直至王哥终老，除了一次性缴纳年金和每月自动收到退休金，王哥不用在这件事
              上再投入任何时间和精力，这正是王哥想要的，每个月固定的退休金，再加上公司养老金，政府CPP养老金，足
              够满足他退休后梦想的生活方式{" "}
            </div>
            <div class="text">
              还有什么比不用每月看投资账单，担心资产涨跌更舒心的事情 了？
              他可以云游四海，做一个快乐似神仙的钓鱼翁了
              从王哥的例子，我们再捋一遍年金的显著三个优点：{" "}
            </div>
            <ul class="product-detail">
              <li class="product-detail">
                第一是零管理，每个月固定派发，不用管理
              </li>
              <li class="product-detail">
                第二是稳定，退休金一旦确定，就不会受到市场波动影响，稳定 发放 
              </li>
              <li class="product-detail">
                第三是持续，退休金终身发放，一直持续到去世
              </li>
            </ul>
            <p></p>
            <div class="text">
              那年金有缺点吗？ 细心的朋友可能发现
              有一个因素决定了这笔年金的回报水平，那就是王哥的寿命。
              跟所有退休金一样，年金所派发的退休金在持有人去世时就会停止。
              如果王哥活到85岁，他可以领到$1,987x12x25=$596,100加币。
              如果王哥活到90岁，他可以领到$715,320加币。
              如果王哥非常长寿，活到105岁，那么他可以领到$1,072,980加币。
              王哥活得越久，他能保证领到的钱就越多，50万的回报率就越高。
              那万一王哥70岁就去世了呢？ 那么他只领到了$238,440加币的退休金，
              剩下的本金（$261,560加币），年金公司会支付给受益人，王哥的家人
              也就是仅仅领回来本金，50万的回报率是0%。{" "}
            </div>
            <div class="text">从上面的例子我们可以看出年金比较适合如下人群</div>
            <ul class="product-detail">
              <li class="product-detail">
                接近或者已经退休，寻求固定退休收入的
              </li>
              <li class="product-detail">担心寿命超过资金所能维持的时间的</li>
              <li class="product-detail">
                不善理财，担心市场波动影响退休收入的 
              </li>
              <li class="product-detail">年金是一种退休方案的解决方式之一</li>
            </ul>
            <p></p>

            <div class="text">
              我觉得它最大的优势就是：稳稳的确定性。
              这是很多人在快要退休时所追求的。
              反过来看，如果我们更在乎的是投资收益，愿意花时间理财，积
              极参与市场波动且愿意承担风险，
              那么有可能会带来更高退休收入的方案可以选择。
              比如租金收入（投资房），分红收入（共同基金、银行股票、
              REIT私募基金），资本增值收入（买卖房地产）等。
              年金这种产品在加拿大主在各大保险公司都可以买到，定制化程
              度也较高，可以满足不同人具体需求，量身定制。
            </div>
            <div class="text">
              最后总结一下，年金可以被看做一种退休金，它的三个最重要的特点就是零管
              理、稳定和持续，适合寻求稳定，不愿意操心，预期寿命长，惧怕市
              场波动的退休人士。 年金的缺点是收益率受寿命长短影响，差异较大。
              年金不太适合喜欢自己理财，或者愿意参与管理并承担一定风
              险，追求更高收益率的人士。{" "}
            </div>
            <div class="text">
              好啦，这款保险大致先介绍到这里，当然选择不同保障里面有更多详细条款，如果有问题请咨询我们的理财顾问啦。{" "}
            </div>
          </div>
        </div>
      ) : (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>Annuity</h2>
            </div>
            <div class="text">
              Annuities are popular financial products that often guarantee
              income throughout the course of your retirement. While you may
              even have access to customizable features and riders.
            </div>
            <h3>What Is an Annuity?</h3>
            <div class="text">
              Simply put, an annuity is a financial contract between an
              insurance company and an individual that allows the individual to
              pay money now in exchange for a guaranteed and steady stream of
              income in the future. The individual who has the annuity either
              pays for it all up front or in a series of regular payments.
            </div>
            <div class="text">
              As the names would suggest, an immediate annuity begins paying out
              right away, while a deferred annuity begins paying out after a
              certain amount of time. Annuities can also use a different
              structure depending on the needs of the individual. They can pay
              out over the course of a lifetime, or they can pay out for just a
              set amount of time.
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://blog.mint.com/wp-content/uploads/2020/09/how-do-annuities-work.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="text">
              There are also two other annuity structures: fixed and variable.
              With a fixed annuity, the rate of return for the individual
              investor is set and won't fluctuate throughout the life of the
              annuity. Some fixed annuities can be indexed to a specific index
              fund or market index as well.
            </div>
            <div class="text">
              When it comes to a variable annuity, the insurance company you buy
              the annuity from will invest your money in different funds and
              accounts, paying out based on the performance of those funds and
              accounts. You can also be in control of the investments in your
              annuity if you have a variable contract. On the other hand, it can
              also be riskier in the case of a market downturn.
            </div>

            <div class="text">For details please contact our advisor </div>
          </div>
        </div>
      )}
    </>
  );
};

const yayi = (isEnglish) => {
  return (
    <>
      {!isEnglish ? (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>牙医保险</h2>
            </div>
            <div class="text">
              俗话说“牙痛不是病，痛起来不要命”，虽然很多人或许会觉得自己未必一定需要去看牙医，但是...千万要做好这个准备！因为万一牙痛起来真是hold不住，
              轻微的蛀牙、牙周炎等问题，你可能完全没感觉，但等到牙痛才去看牙医的话，可能就不是简单的补牙、洗牙能搞得定了。不但自己受罪，还得花更多的钱去治疗，划不来。还是定期看牙医做口腔检查，发现小问题马上处理更省事省钱
              北美牙医保险（dental insurance）跟医疗保险（Medical Services
              Plam）是分开的，虽然部分医疗保险可能包含一些牙科服务的福利，但大多时候牙医保险需要独立购买（stand-alone
              plan）。
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://forestwooddentistry.ca/wp-content/uploads/BenefitsLandingPage.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <h3>牙医保险报销什么？</h3>
            <div class="text">
              牙医保险通常会将人们看牙医时需要的服务项目报销划分为四个等级类别（Class
              of Services）：
            </div>
            <h4>Class I (Preventive)：I类预防诊断项目</h4>
            <div class="text">
              多数牙医保险报销80%~100%，好保险可以报销100%+免deductible，
              通常包括服务有以下：
            </div>
            <ul class="product-detail">
              <li class="product-detail">口腔检查（Exam）</li>
              <li class="product-detail">
                全口 X光片（Full-Mouth X-Rays），通常限每年报销1次
              </li>
              <li class="product-detail">
                普通洗牙（Routine Cleanings/Prophylaxis），通常限每年报销2次
              </li>
              <li class="product-detail">
                深度洗牙（Periodontal Maintenance/Deep
                Cleanings），有些保险归类为II类
              </li>
              <li class="product-detail">
                含氟涂料（Topical Fluoride/Fluoride Varnish）
              </li>
              <li class="product-detail">
                窝沟封闭（Sealants），可能会限19岁以下
              </li>
              <li class="product-detail">
                牙齿空间保持器（Space
                Maintainers），有些保险归类为II类，且通常限19岁以下
              </li>
            </ul>
            <p></p>
            <h4>Class II (Basic)：II类基础恢复护理项目</h4>
            <div class="text">
              多数牙医保险报销70%~80%，可能会有6个月以上的等待期，好保险能报销80%以上且免等待期，
              项目包括：
            </div>
            <ul class="product-detail">
              <li class="product-detail">补牙（Restorative Fillings）</li>
              <li class="product-detail">
                拔牙、简单口腔手术（Extraction/Oral Surgery）
              </li>
              <li class="product-detail">
                根管治疗（Root Canal/Endodontics），有些保险归类为III类
              </li>
            </ul>
            <p></p>
            <h4>Class III (Major)：III类大型恢复护理项目</h4>
            <div class="text">
              大多数牙医保险只报销50%或以下，而且很可能会有12个月以上的等待期，部分项目甚至不报销，
              项目包括
            </div>
            <ul class="product-detail">
              <li class="product-detail">
                复杂的口腔手术（Complex Oral Surgery），比如拔智齿（Wisdomtooth
                Extraction），但也有可能归类为II类
              </li>
              <li class="product-detail">
                牙周手术（Periodontics），比如牙龈嫁接（Gingival Graft/Gum
                Graft）、牙槽骨嫁接（Bone Grafting）等
              </li>
              <li class="product-detail">假牙冠、烤瓷牙（Crown）</li>
              <li class="product-detail">牙桥（Bridge）</li>
              <li class="product-detail">假牙（Denture）</li>
              <li class="product-detail">植牙（Implant）</li>
            </ul>
            <p></p>
            <h4>Class IV (Orthodontia)：IV类牙齿矫正项目</h4>
            <div class="text">
              这类报销通常跟上面三类项目的报销分开计算，有终生报销上限（Lifetime
              Maximum），有别于年度报销上限，且通常仅限19岁以下。
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://labblog.uofmhealth.org/sites/lab/files/2020-03/lady_in_dental_exam_chair.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <h3>牙医保险不报销什么？</h3>
            <div class="text">
              牙医保险通常不给报销美容牙科（Cosmetic
              Dentistry）项目，因为这些项目并非医疗必须（Medically
              Necessary），比如：
            </div>
            <ul class="product-detail">
              <li class="product-detail">牙齿美白（Teeth Whitening）</li>
              <li class="product-detail">牙齿贴面（Porcelain Veneers）</li>
              <li class="product-detail">隐适美（Invisalign</li>
              <li class="product-detail">
                牙齿粘接（Bonding），如果属于修复性质的话可能会报销
              </li>
            </ul>
            <p></p>
            <h3>牙医保险不报销什么？</h3>
            <div class="text">
              首先，如果你的雇主有提供医疗保险和牙医保险福利的话，请咨询HR如何购买健康保险。雇主提供的保险属于集体保险（group
              insurance），也是给员工的一种福利，通常要比自己在外面购买的个人保险（individual
              insurance）更加优惠，同保费的情况下保险报销更多，而且没有waiting
              period（等待期）限制。
            </div>
            <div class="text">
              注：不同地、不同保险公司的牙医保险或者牙医折扣计划报价会有差异，建议咨询专业人士来比较哪种更适合自己。
            </div>
            <h3>牙科检查费用是多少？</h3>

            <div class="text">
              基础牙科检查费用为$110左右。但是，您最终可能会支付更多费用。如果是您的第一次看牙，可能需要支付X光和/或清洁费用。如果由于问题需要再检查，您可能需要支付X射线和/或其他分析技术的费用。
                X射线的价格为$50-200。基本清洁费用可达$250左右。  
              基于各种因素，牙科服务成本大幅波动。  
               普通拔牙费用为$75-200/每颗，拔掉阻生牙的成本要高得多，在$800-4000。
              根管费用为$300-2,000。
              牙冠需要$500-3,000（牙冠的成本取决于使用的材料;牙齿的大小，位置和状况）。
              牙科植入物花费$1,000-3,000。 假牙成本为$500-3,000。
              由于各种相关成本，所有这些价格标签都会上涨。
              对于价格每个省的收费标准略有不同。
            </div>
          </div>
        </div>
      ) : (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>Dental Insurance</h2>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://forestwooddentistry.ca/wp-content/uploads/BenefitsLandingPage.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <h3>Basic Health & Dental Plans (most popular)</h3>

            <ul class="product-detail">
              <li class="product-detail">Up to 80% Deductable</li>
              <li class="product-detail">Prescription Drugs</li>
              <li class="product-detail">Dental Care </li>
              <li class="product-detail">Paramedical Services类</li>
              <li class="product-detail">Vision Care & Eye Exams </li>
              <li class="product-detail">Major Medical & Hospital </li>
              <li class="product-detail">Travel Insurance</li>
            </ul>
            <p></p>
            <h3>Enhanced Health & Dental Plans</h3>

            <ul class="product-detail">
              <li class="product-detail">Up to 100% Deductable</li>
              <li class="product-detail">Prescription Drugs</li>
              <li class="product-detail">Dental Care</li>
              <li class="product-detail">Paramedical Services</li>
              <li class="product-detail">Vision Care & Eye Exams</li>
              <li class="product-detail">Major Medical & Hospital</li>
              <li class="product-detail">Travel Insurance</li>
              <li class="product-detail">Life Insurance</li>
              <li class="product-detail">Accidental Death & Dismemberment</li>
              <li class="product-detail">Dependent Life Insurance</li>
            </ul>
            <p></p>

            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://labblog.uofmhealth.org/sites/lab/files/2020-03/lady_in_dental_exam_chair.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="text">For details please contact our advisor </div>
          </div>
        </div>
      )}
    </>
  );
};

const jiaoyu = (isEnglish) => {
  return (
    <>
      {!isEnglish ? (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>注册教育基金计划（RESP）</h2>
            </div>
            <div class="text">
              注册教育储蓄计划，也被称为RESP，是为那些希望为孩子高中毕业后的教育进行储蓄的父母而设立的特殊账户上，这个计划可以给你每年20%的无风险回报保证，并得到加拿大政府的支持。
            </div>
            <h3>加拿大的中学后教育</h3>
            <div class="text">
              我觉得在我们深入讨论注册教育储蓄计划之前，有必要描绘一下加拿大的中学后教育费用。虽然中学后教育可能看起来不像以前那样辉煌，但人们越来越一致地认为，完成高中学业是走上学术道路的前提。好消息是，从长远来看，接受中学后教育的加拿大人在经济上确实要好得多，但是，你知道获得中学后教育学位需要多少钱吗？根据加拿大统计局的数据，在2020年，当地一所普通的中学后学校每年将花费约11,000-21,000加元，按平均四年完成计算，将至少需要约40,000加元。此外，这只是学费，不包括教科书、房租、食品费用、交通等其他费用。那么17年后呢？随着通货膨胀，这一数额将增加35%，因此在加拿大，四年的课程可能需要70,000至120,00加元。
            </div>
            <h3>中学后教育的融资选择</h3>
            <div class="text">
              加拿大学生联合会估计，目前平均学生债务超过28,000加元。此外，加拿大学生贷款计划发现，大多数学生需要10年才能还清他们的学生贷款。一个大学毕业生在22岁完成学业，并有学生贷款，这将意味着他们在32岁之前无法考虑像买房这样的任何大决定。到那时，如果他们选择生孩子，那么他们将无法开始为自己的孩子的教育进行储蓄，这样的循环就开始了。
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://www.mawer.com/assets/Articles/SM-RESP.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <h3>RESP如何运作</h3>
            <div class="text">
              类似于免税储蓄帐户（TFSA）或注册退休储蓄计划（RRSP），RESP在加拿大是一个可以增长的投资账户，具有税收优势，政府也将免费提供资金。RESP有三个好处：
            </div>
            <ul class="product-detail">
              <li class="product-detail">
                你每交款一加元，政府将捐献20%，每年最多交款500加元或一生最多交款7200加元。如果整个家庭的收入低于或等于47,640元，政府将额外给RESP（CLB）补贴2000元。
              </li>
              <li class="product-detail">
                RESP有税收优势，因为在储蓄阶段，投资的增长都是避税的，这意味着不需要立即为收益或利息交税。当您的孩子准备上中学后教育时，提取RESP款项被认为是孩子的应税收入，这对孩子的税收影响很小甚至没有。
              </li>
              <li class="product-detail">
                RESP很灵活!
                参保者是灵活的，可以由亲属赠予。还可以根据自己的风险和收益选择投资方式，包括储蓄、债券、共同基金、EFT、股票等。
              </li>
            </ul>
            <p></p>
            <div class="text">
              开始储蓄的最佳时间是你的孩子第一次坐婴儿车里的时候，当然你可以在以后追补资金。在一个理想的情况下，如果你每年向计划投资2,500加元，每年有5%的回报，持续17年，计划中应该有80,000加元存款，足以支付四年制学院或大学学位的学费费用。
            </div>
            <div class="text">
              RESP可以持续36年，因此您的孩子可以灵活地决定是否要继续深造，或者可以将钱转给兄弟姐妹。如果家庭中没有孩子决定上学，RESP可以转移到父母的RRSP中，但需要归还政府的补助金。
            </div>
            <div class="text">
              加拿大是为数不多的为加拿大人的未来教育提供免费资金的国家之一，在孩子小的时候开始考虑这个问题是明智之举。没有其他投资能给你20%的回报保证!
              如果你有兴趣了解更多关于在加拿大如何计划孩子的教育基金，请跟我们的理财规划师联系。
            </div>
          </div>
        </div>
      ) : (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>RESP - Registered Education Saving Plan</h2>
            </div>
            <h3>Invest in your child's future</h3>
            <div class="text">
              Post-secondary education is expensive. And getting more so.
              Registered Education Savings Plans (RESPs) can help, allowing your
              money to grow tax-free until your child is ready for university,
              college or trade school
            </div>
            <h3>Tax-free growth plus free government grants</h3>
            <div class="text">
              RESPs allow savings to grow tax-free until your child is ready to
              go to college, university, trade school, or any other eligible
              post-secondary educational institution. Under the family RESP
              plan, if your child decides not to attend, the RESP can be
              transferred to another beneficiary such as a sibling. RESP assets
              can also be transferred into your RRSP, provided you have enough
              contribution room left.
            </div>
            <div class="text">
              Each child beneficiary can collect up to $500 per year from the
              Canada Education Savings Grant (CESG) program, to a maximum of
              $7,200 over the life of the RESP. That's an automatic 20% return
              on your investment! The simplest way to achieve this is to set up
              a convenient pre-authorized contribution of about $208 a month.
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://www.mawer.com/assets/Articles/SM-RESP.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="text">
              There is also a BC Training and Education Savings Program (BCTESP)
              grant, designed to help families start planning and savings
              earlier for their children’s post-secondary education. It provides
              $1,200 for your child’s RESP with no matching or additional
              contribution on your part. Grant applications can be submitted any
              time after the child’s sixth birthday up to the day before their
              ninth birthday. More information on this program can be found on
              the BC government website.
            </div>
            <div class="text">
              To maximise the growth of your RESP, especially if you start early
              and have a long time horizon, consider adding investments with
              potentially higher returns such as mutual funds† and ETFs†. Secure
              products like an RESP Savings Account and term deposits can round
              out your RESP portfolio. Your advisor will be able to assist you
              with your portfolio strategy.
            </div>
            <div class="text">
              For additional Grants and informations please contact with our
              advisor
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const yanglao = (isEnglish) => {
  return (
    <>
      {!isEnglish ? (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>注册养老储蓄计划（RRSP）</h2>
            </div>
            <h3>什么是RRSP？</h3>
            <div class="text">
              注册退休储蓄计划 (RRSP)
              是一种在加拿大联邦政府注册的储蓄计划，以便您可以为退休进行供款。
              当您向RRSP供款时，您的资金可享受“税收优惠”，这意味着在您供款的那一年，供款可以免税。随后，只要这笔资金保留在RRSP内，RRSP持有的投资在取款之前赚取的任何投资收入都可以延税增长。
              RRSP供款可抵扣税款，这意味着您当年的纳税申报表上可以扣减这笔金额，这可能会减少您支付的税款总额。
            </div>
            <h3>RRSP如何运作？</h3>
            <div class="text">
              您可以灵活地在RRSP各种合资格的投资之间持有和转移资金。这些投资包括：
            </div>
            <ul class="product-detail">
              <li class="product-detail">现钞</li>
              <li class="product-detail">储蓄账户</li>
              <li class="product-detail">
                GIC（担保投资证）：一种在固定期限内提供回报率保证的投资。
              </li>
              <li class="product-detail">
                互惠基金：一种汇集个人投资者的资金，并用其购买股票、债券或其他互惠基金等证券的投资基金。与大多数其他类型的投资基金不同，互惠基金是“开放式的”，这意味着随着投资人数的增加，基金也将发行新的单位。
              </li>
              <li class="product-detail">
                政府和企业储蓄债券：类似于金融要求权 (I-Owe-You)
                的投资，其中投资者向公司/政府提供贷款，通常可获得固定的收益率。
              </li>
              <li class="product-detail">
                在指定证券交易所上市的证券，包括单只股票。
              </li>
              <li class="product-detail">
                ETF：一种具有与股票或债券市场指数相同的投资组合，并在证券交易所进行交易的投资基金。
              </li>
            </ul>
            <p></p>
            <p></p>

            <h3>达到什么年龄才有资格向RRSP供款？</h3>
            <div class="text">
              开设RRSP没有最低年龄要求。
              但是，某些金融机构可能要求客户达到法定成年年龄。只要您是加拿大居民、赚取收入且报税，就可以在年满71岁当年的年末开设RRSP并供款。
            </div>
            <h3>开设RRSP的最佳年龄是多大？</h3>
            <div class="text">
              因人而异。不过一般而言，越早越好！开始为退休而投资从不会为时过早。实际上，根据您所持有的投资产品的类型，及早投资可能会让您享有延税复利的收益。
            </div>
            <h3>投资RRSP有哪些好处？</h3>
            <ul class="product-detail">
              <li class="product-detail">
                延税储蓄：只要保留在您的RRSP中，该计划中持有的投资所赚取的任何投资收益都可延税。{" "}
              </li>
              <li class="product-detail">
                抵税：您的RRSP供款可以抵税，并可帮助减少您支付的所得税总额。{" "}
              </li>
              <li class="product-detail">
                优化抵税：您可将收入较低的年份未使用的RRSP供款额度顺延至将来收入较高的年份使用。当您处于较高的税级时，这可以帮助您节税。
              </li>
              <li class="product-detail">
                收入分配：如果您的收入高于配偶或普通法伴侣，则向配偶RRSP供款可能有助于减少您所支付的税款总额。
              </li>
              <li class="product-detail">
                为首次购房或教育提供资金：根据购房者计划或终身学习计划
                (LLP)，您可从RRSP中取款为首次购房或教育提供资金，而无需立即纳税。
              </li>
            </ul>
            <p></p>
            <p></p>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://www.peterwatsoninvestments.com/wp-content/uploads/2017/03/RRSP.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <h3>我何时可以取钱？</h3>
            <div class="text">
              只要您的资金不在锁定计划中，您就可以随时从RRSP中取款，但取款通常会包括在您的收入中并需要在取款当年纳税。
              通常，取款的一部分将被代扣并汇给政府，作为您当年应缴纳所得税的预付款。
              根据您在取款当年的应纳税收入额，推迟至应税收入降低的年份再取款比较有利。
              此外，与免税储蓄账户 (TFSA)
              取款不同，RRSP的取款不会在取款后的一年内重新加入您的供款额度中。
            </div>
            <h3>我的RRSP可保持开通多久？</h3>
            <div class="text">
              年满71岁当年的12月31日之后，您将不可持有RRSP。
              到那时，您将从RRSP中一次性取款，并将其转入注册退休收入基金或购买年金。
            </div>
            <h3>2021年的RRSP供款截止期限是什么？</h3>
            <div class="text">
              2021纳税年度的RRSP供款截止期限为2022年3月1日。如要了解重要的RRSP供款日期，请浏览RRSP截止期限指南。
            </div>
          </div>
        </div>
      ) : (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>RRSP - Registered Retirement Saving Plan</h2>
            </div>
            <h3>RRSP contribution cheat sheet</h3>
            <div class="text">
              18% or $27,830(this number change every year ) Contribution room
            </div>
            <div class="text">
              In 2021, your contribution room is 18% of last year’s earned
              income or $27,830, whichever’s smaller – plus, any unused
              contribution room carried over from before.
            </div>
            <h3>March 1 (Last day for contribution)</h3>
            <div class="text">
              Contributions made in the first 60 days of the year can be used to
              reduce your taxable income for the previous year or any year
              after. In the year that you turn 71, the last day to contribute is
              Dec. 31.{" "}
            </div>
            <h3>Spousal RRSP (Save with your partner)</h3>
            <div class="text">
              If you earn significantly more than your spouse or common-law
              partner, you may benefit from contributing to a spousal RRSP. This
              could help you save taxes when the two of you withdraw from your
              RRSPs at retirement.{" "}
            </div>
            <div class="text">To be eligible open an rrsp you must be: </div>
            <ul class="product-detail">
              <li class="product-detail">A Canadian resident</li>
              <li class="product-detail">Under 71 years old</li>
              <li class="product-detail">Have filed income tax before</li>
            </ul>
            <p></p>

            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://www.peterwatsoninvestments.com/wp-content/uploads/2017/03/RRSP.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div class="text">To open an account, meet with an advisor.</div>
          </div>
        </div>
      )}
    </>
  );
};

const mianshui = (isEnglish) => {
  return (
    <>
      {!isEnglish ? (
        <div class="portfolio-details product-detail">
          <div class="auto-container">
            <div class="sec-title">
              <h2>免税账户（TFSA）</h2>
            </div>
            <h3>什么是免税储蓄账户（TFSA）？</h3>
            <div class="text">
              免税储蓄账户(TFSA)是可以赚取免税收入的注册税收优惠储蓄账户。
              您可以假设免税储蓄账户是一个篮子，篮子内可以摆放符合条件的投资产品，其产生的利息、资本收益与股息分派均无需纳税。
              无论您要为梦幻婚礼、应急基金、首套住房或漫长假期进行储蓄，免税储蓄账户均可帮助您更快达成目标。为了帮助您入门，我们将分别介绍：什么是免税储蓄账户、免税储蓄账户的运作机制及其如何让您的储蓄计划受益。
            </div>
            <h3>免税储蓄账户何时推出？有多少额度？</h3>
            <div class="text">
              加拿大政府于2009年推出免税储蓄账户，旨在鼓励符合条件的加拿大人进行储蓄。
              2009年到2021 年每年额度如下：
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src={require("../../assets/images/resource/tfsa.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="text">
              2022 年供款额度为 $6000；到2022 年的总供款为： $81500。
            </div>
            <h3>免税储蓄账户的运作机制是什么？</h3>
            <div class="text">
              您可在TFSA中持有符合条件的投资产品，例如现金、股票、债券、互惠基金等，而且您可随时取出账户中的供款、利息、资本收益和分派的股息且无需纳税
              (报税时也无需将取款作为收入申报)。
              每年，加拿大政府都会确定TFSA账户持有人当年的供款金额上限。此限额被称作供款额度。加拿大人自年满18岁的日历年起，其供款额度开始每年累积。如果您在某年的供款金额未达到额度，则额度将结转至来年的供款额度。
            </div>
            <h3>谁有资格获得TFSA？</h3>
            <div class="text">
              TFSA适用于所有年龄在18岁或以上、拥有社会保险号（SIN）的加拿大居民。{" "}
            </div>
            <h3>非加拿大居民的资格要求</h3>
            <div class="text">
              如果您在开立TFSA账户之后出于税务原因成为非加拿大居民，您可保留自己的TFSA账户，而且通过账户赚取的收入无需在加拿大纳税。
              但是，如果您在成为非居民期间供款，则除了某些例外情况，您每月须为账户中的供款缴纳1%税费。您可能还需要缴纳其他税费。此外，在您成为非加拿大居民的任何年份，TFSA供款额度都不会累积。{" "}
            </div>
            <h3>开设免税储蓄账户有什么好处？</h3>

            <ul class="product-detail">
              <li class="product-detail">
                增强灵活性。 免税储蓄账户是帮助您灵活为实现多种短期和长期目标进行储蓄的储蓄解决方案。它可帮助您实现自己的储蓄目标，在有需要时您也可以随时取款。
              </li>
              <li class="product-detail">
                享受免税待遇，实现储蓄增长。 通过免税储蓄账户赚取的任何投资收入均无需纳税，而且您可持有各种符合条件的投资产品，包括现金、股票、担保投资证和互惠基金。投资回报潜力越高，储蓄的免税增长就越快。
              </li>
              <li class="product-detail">
                退休策划。 如果您的注册退休储蓄计划供款额度已耗尽或您已年满71岁且不能继续持有注册退休储蓄计划账户，免税储蓄账户就可作为您额外的税收优惠储蓄账户，对您个人的注册退休储蓄计划形成补充。通过向TFSA供款，账户中赚取的任何收入均免于纳税，即便取款也是如此。{" "}
              </li>
              <li class="product-detail">
                取款。 从免税储蓄账户中取款无需纳税。这可让TFSA成为为大额消费储蓄的绝佳工具。需要动用资金时可以取款，无需纳税。这样，您就可将更多资金用于自己珍视的方面。
              </li>
            </ul>
            <p></p>
            <p></p>
            <div class="text">
              免税储蓄账户中可持有各种符合条件的投资产品，常见的投资产品类型包括：
            </div>
            <ul class="product-detail">
              <li class="product-detail">现钞 </li>
              <li class="product-detail">互惠基金 </li>
              <li class="product-detail">担保投资证 (GIC) </li>
              <li class="product-detail">股票</li>
              <li class="product-detail">债券</li>
            </ul>
            <p></p>
            <p></p>
            <div class="text">
              视您的储蓄目标而定，各种类型的投资产品均各有千秋。润达财务顾问可基于您愿意承担的风险水平，帮助您评估哪种投资选择最适合您，欢迎进行咨询。
            </div>
          </div>
        </div>
      ) : (
        <div class="portfolio-details product-detail">
          <div class="auto-container">
            <div class="sec-title">
              <h2>TFSA- Tax Free Saving Account</h2>
            </div>
            <h3>What is a TFSA?</h3>
            <div class="text">
              The TFSA was introduced by the Canadian government in 2009 to help
              Canadians accumulate wealth by investing and saving on taxes.
              Canadian residents who are 18 and older with a valid Social
              Insurance Number (SIN) can easily set up an account
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src={require("../../assets/images/resource/tfsa.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <h3>Know your limits</h3>

            <ul class="product-detail">
              <li class="product-detail">
                All Canadians aged 18 and over can contribute up to
                $6,000 annually,（ number may different every year since 2009）
                as a combined total of TFSAs held at all institutions.
              </li>
              <li class="product-detail">
                If you've never contributed to a TFSA, and you were eligible to
                contribute since they were introduced in 2009, you can
                contribute a total of up to $81,500, which means all of your
                unused contributions since 2009 carry over.
              </li>
              <li class="product-detail">
                Watch those contributions! If you over-contribute, you’ll be
                penalized 1% on the excess every month, until you withdraw it.
              </li>
              <li class="product-detail">
                If you want to replace money you’ve withdrawn, you’ll have to
                wait until the following year to regain the contribution room.
              </li>
            </ul>
            <p></p>
          </div>
        </div>
      )}
    </>
  );
};

const anjie = (isEnglish) => {
  return (
    <>
      {!isEnglish ? (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>
                借款投资计划
                <br />
                loan investment
              </h2>
            </div>
            <div class="text">
              我们可以提供Manulife bank , B2B Bank , National Bank and IA
              financial 的贷款， 无任何抵押物， 只需要你有稳定的收入，
              良好的信用记录并没有太多的贷款，
              贷款的利息可以抵税，并瞬间放大你的投资资产。
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://www.associateshomeloan.com/wp-content/uploads/2019/08/investment-property-loan-with-bad-credit-min-e1567016634203.jpeg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <h3>借款投资计划案例:</h3>
            <div class="text">
              张小姐是一名地产经纪，并自己注册了PREC， 张小姐很勤奋，
              每年收入大概$20-$30 万， 除去她每年存满RRSP 额度抵税以外，
              张小姐没有其他避税手段， 她既想有笔钱去投资， 又想抵税，
              由于张小姐的收入较高， 做借钱投资就很适合她， 利用杠杆原理如下：
              由我们润达理财顾问帮张小姐向B2B Bank 申请借款$300,000,
              根据她的信用记录，资产负债比，她的TDSR 小于%40（TDSR
              详情请咨询我们的理财顾问） .审批通过， 张小姐得到了$300,000
              的贷款。 这时我们用着$ 30 万帮她做了稳定回报，低风险的基金组合，
              年回报如果6% 如下：
            </div>

            <div class="text">$300,000 ---------------------年利息$9600 </div>
            <div class="text">
              $300,000 ---------------------年回报6%=$18000
            </div>
            <div class="text">
              假设张小姐的税阶在45%， 所以 $9600*45%=$4320,
              ,张小姐因为借了30万，
              产生的利息可以抵税，所以实际上张小姐的借款本金每年为$9600-$4320=$5300。
              $5300 赚了 $18000, 等于300% 回报。 况且年回报6%轻松做到。
            </div>
            <div class="text">
              这就是借款投资的精髓所在。需要进一步了解怎样轻松做到300%回报，
              请跟我们的理财顾问一对一咨询。
            </div>
          </div>
        </div>
      ) : (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>Loan Investment</h2>
            </div>
            <div class="text">
              We can provide loans from Manulife Bank, B2B Bank, National Bank
              and IA Financial without any collateral. You only need to have a
              stable income and a good credit record. There are not many loans.
              And instantly magnify your investment assets.
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://www.associateshomeloan.com/wp-content/uploads/2019/08/investment-property-loan-with-bad-credit-min-e1567016634203.jpeg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <h3>Loan Investment Plan Examples:</h3>
            <div class="text">
              Miss Zhang is a real estate agent and has registered PREC by
              herself. She is very diligent and earns about $200,000 to $300,000
              per year. Except that she saves the full RRSP amount for tax
              deduction every year, Miss Zhang has no other means of tax
              avoidance. The principle of leverage is as follows: Our Runda
              Financial consultant helped Miss Zhang to apply for a loan of
              $300,000 from B2B Bank. According to her credit history and
              asset-liability ratio, her TDSR was less than %40. It was approved
              and Ms. Zhang received a $300,000 loan. At this time, we used
              $300,000 to help her do a stable return, low risk fund portfolio,
              if the annual return of 6% :
            </div>

            <div class="text">
              $300,000 ---------------------annual interest $9600{" "}
            </div>
            <div class="text">
              $300,000 ---------------------annual return 6%=$18000
            </div>
            <div class="text">
              Suppose Miss Zhang's tax bracket is at 45%， so $9600*45%=$4320 .
              Because Ms. Zhang borrowed 300,000 yuan, the interest generated
              can be tax deductible, so in fact, the principal of Ms. Zhang's
              loan is $9600-$4320=$5300 per year. $5,300 made $18,000, which is
              a 300% return. And 6% a year is easy.
            </div>
            <div class="text">
              That's the essence of borrowing to invest. For more information on
              how to easily achieve 300% returns, please talk to one of our
              financial advisors.
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const lvyou = (isEnglish) => {
  return (
    <>
      {!isEnglish ? (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>旅游保险</h2>
            </div>
            <div class="text">
              所有来访加拿大的访客，工签，留学生，探亲
              或者已经那大加拿大的登陆纸了的都需要给自己以及家人买一份旅游保险，
              旅游保险只要保紧急医疗， 众所周知加拿大的紧急医疗挂号费就是$1050,
              还不算药费，医生诊断费以及各种检查费种种。
            </div>
            <div class="text">
              紧急医疗不包括慢性病和已经发生的病症。 以下是保额的单价：{" "}
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src={require("../../assets/images/resource/lvyou1.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src={require("../../assets/images/resource/lvyou2.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>Travel Insurance</h2>
            </div>
            <div class="text">
              While you’re staying in Canada, enjoying all it has to offer, let
              us take care of your emergency medical needs. We’re happy to
              provide immigrants and visitors to Canada, international students
              and those looking for Super Visa insurance, with emergency medical
              coverage. Plans feature:
            </div>
            <ul class="product-detail">
              <li class="product-detail">
                Online Claims make it quick and easy to send your claim from
                anywhere using any device.
              </li>
              <li class="product-detail">
                Coverage for COVID-19 related emergency medical expenses.
              </li>
              <li class="product-detail">
                No waiting period if you apply before arriving in Canada or are
                replacing an existing emergency medical plan issued by a
                Canadian insurer.
              </li>
              <li class="product-detail">
                Choice of deductible - $0,$75, $100, $500 or $1,000.
              </li>
              <li class="product-detail">
                Choice of insurance amount -$10000, $25,000, $50,000, $100,000
                or $150,000 and $300,000
              </li>
              <li class="product-detail">
                No medical questions if you’re under 55 years of age.
              </li>
              <li class="product-detail">
                Side-trip travel coverage for up to 30 days to any other
                country, as long as the majority of your trip is spent in Canada
                and there's no travel advisory to "Avoid non-essential travel"
                or "Avoid all travel" from the Government of Canada for your
                destination.
              </li>
              <li class="product-detail">
                24/7 travel assistance service provided in a number of different
                languages.
              </li>
              <li class="product-detail">
                Direct billing with many health providers, so you can keep your
                money in your pocket.
              </li>
              <li class="product-detail">
                Virtual Care for minor emergencies. You can speak to a medical
                professional over the phone or video instead of having to go to
                a hospital or clinic.
              </li>
            </ul>
            <p></p>
            <div class="row">
              <div class="col-lg-12">
                <div class="image-box">
                  <img
                    src="https://assets.visitorscoverage.com/production/images/Visitor-Medical-vs-Trip-Insurance-Header.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="text">For details please contact our advisor</div>
          </div>
        </div>
      )}
    </>
  );
};

const Rongzi = (isEnglish) => {
  const local = `/rongzi.pdf`;

  return (
    <>
      {!isEnglish ? (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>即时融资安排</h2>
            </div>
            <div class="text">
              IFA 策略作为一种高端的财务策略，将杠杆投资的原理运用到保单中，
              真正做到了一笔款项两种用途，既把握了更优的投资机会，又最大限度
              做到个人资产保值和财富传承，是当前低利率环境下增加个人和家庭资
              产的最优方式，非常适合高资产和高税收的人士和企业主。申请 IFA 策
              略是需要很多技巧和经验的，一个优秀的理财顾问可以帮助客人量身定
              制最适合客人未来财富管理需要的方案。
            </div>
            <a
              href="https://runda-public-files.s3.us-east-2.amazonaws.com/rongzi.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h3>点击查看详情</h3>
            </a>
          </div>
        </div>
      ) : (
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <h2>IFA - Immediate Financing Arrangement</h2>
            </div>
            <h3>What is an immediate finance arrangement</h3>
            <ul class="product-detail">
              <li class="product-detail">
                a financial planning strategy allowing to meet the client's
                current and future insurance needs while providing immediate
                access to funds for investment purpose
              </li>
              <li class="product-detail">
                Financial leverage with life insurance
              </li>
              <li class="product-detail">
                The borrowed funds are used to earn income from a business or
                property
              </li>
              <li class="product-detail">
                Tax benefits inprove cash flows - interest deductiblity and
                collateral insurance deduction
              </li>
            </ul>
            <p></p>
            <h3>Classic IFA Structures</h3>
            <div class="text">
              With these structures, there are various options and illustrations
              can be highly customized: e.g.
            </div>
            <ul class="product-detail">
              <li class="product-detail">
                Client repay entire loan after 10 years
              </li>
              <li class="product-detail">
                Client pays interests and then borrows it back when CSV grows
              </li>
              <li class="product-detail">
                Client pays premium for a few years then borrows for remaining
                years
              </li>
            </ul>
            <p></p>
            <h3>Three Key IFA Structures:</h3>
            <h4>100% CSV Financing</h4>
            <ul class="product-detail">
              <li class="product-detail">No additional collateral</li>
              <li class="product-detail">Client pays interest</li>
            </ul>
            <p></p>
            <h4>100% Premium Financing</h4>
            <ul class="product-detail">
              <li class="product-detail">
                Client provide additional collateral - A.Client pays interest;
                B.Client pays interest then borrows it back at end of year
              </li>
              <li class="product-detail">
                Illustration will not generate an IRR
              </li>
            </ul>
            <p></p>
            <h4>
              With these structures, there are various options and illustrations
              can be highly customized: e.g.
            </h4>
            <ul class="product-detail">
              <li class="product-detail">
                Client repay entire loan after 10 years
              </li>
              <li class="product-detail">
                Client pays interests and then borrows it back when CSV grows
              </li>
              <li class="product-detail">
                Client pays premium for a few years then borrows for the
                remaining years
              </li>
            </ul>
            <p></p>
            <h3>Benefits of IFA</h3>
            <ul class="product-detail">
              <li class="product-detail">
                Provide life insurance protection and preserves cash flow for
                investment
              </li>
              <li class="product-detail">Cash values grow tax deferred</li>
              <li class="product-detail">
                Loan interest and insurance premiums may be deductible
              </li>
              <li class="product-detail">
                Loan repayment may be deferred until death
              </li>
              <li class="product-detail">
                Timing and amount of loans are flexible
              </li>
              <li class="product-detail">Borrowed funds received tax free</li>
              <li class="product-detail">
                If corporate proceeds generate a CDA credit
              </li>
            </ul>
            <p></p>
            <h3>Target Client Profile</h3>
            <ul class="product-detail">
              <li class="product-detail">
                Individual / private corporation who needs life insurance
                protection{" "}
              </li>
              <li class="product-detail">Open to leveraging </li>
              <li class="product-detail">
                If required, Individual / corporation has additional liquid
                assets for collateral{" "}
              </li>
              <li class="product-detail">
                Individual / corporation has steady cash flow{" "}
              </li>
              <li class="product-detail">
                Individual / corporation has income available to use deductions{" "}
              </li>
              <li class="product-detail">
                Individual’s / corporation’s income tax at high rate.{" "}
              </li>
              <li class="product-detail">
                Clients with at least 2x liquid net worth to the requested loan
                amount & feel the return on their non-registered investments can
                have great investment returns{" "}
              </li>
            </ul>
            <p></p>
            <h3>Working an IFA: The Real Experience</h3>
            <ul class="product-detail">
              <li class="product-detail">
                Significant administrative component
              </li>
              <li class="product-detail">Qualifying for the loan </li>
              <li class="product-detail">
                Bank Underwriting differs from insurance underwriting{" "}
              </li>
              <li class="product-detail">
                In subsequent years there are repeating steps{" "}
              </li>
              <li class="product-detail">
                In subsequent years you must qualify for lending (there is
                annual review and fees from the lender){" "}
              </li>
              <li class="product-detail">There are bank fees </li>
              <li class="product-detail">
                There are minimum case sizes (that vary){" "}
              </li>
              <li class="product-detail">Tax rates matter </li>
              <li class="product-detail">
                IFAs are great conversations to have with your client, many end
                up NOT leveraging in the end{" "}
              </li>
            </ul>
            <p></p>
            <h3>Reminder</h3>
            <ul class="product-detail">
              <li class="product-detail">
                Client must have insurance needs & insurability.{" "}
              </li>
              <li class="product-detail">IFA is leveraging. </li>
              <li class="product-detail">IFA is not for everyone. </li>
              <li class="product-detail">IFA is not “Free Insurance”. </li>
            </ul>
            <p></p>
            <div class="text">
              This is most popular products for business owners For details
              please contact with our advisor{" "}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default function Data({ id, isEnglish }) {
  const map = {
    0: (isEnglish) => fenhong(isEnglish),
    1: (isEnglish) => yiwai(isEnglish),
    2: (isEnglish) => zhongji(isEnglish),
    3: (isEnglish) => huli(isEnglish),
    4: (isEnglish) => canji(isEnglish),
    5: (isEnglish) => guzhe(isEnglish),
    6: (isEnglish) => nianjin(isEnglish),
    7: (isEnglish) => yayi(isEnglish),
    8: (isEnglish) => jiaoyu(isEnglish),
    9: (isEnglish) => yanglao(isEnglish),
    10: (isEnglish) => mianshui(isEnglish),
    11: (isEnglish) => anjie(isEnglish),
    12: (isEnglish) => lvyou(isEnglish),
    13: (isEnglish) => Rongzi(isEnglish),
  };
  return map[id](isEnglish);
}
