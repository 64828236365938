import { SELECT_ENGLISH, SELECT_CHINESE } from "./types";

export const selectEnglish = () => ({
  type: SELECT_ENGLISH,
  payload: {},
});

export const selectChinese = () => ({
  type: SELECT_CHINESE,
  payload: {},
});
